import React from 'react';
import { Avatar, Stack, Tooltip } from '@mantine/core';
import { DealParams } from 'app/models/deal-params';
import cleanUUID from 'app/views/components/functions/cleanUUID';
import getInitials from 'app/views/components/functions/getInitials';
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions';
import { DetailSection } from 'app/views/components/detailSections/detail-section';

interface DealDetailsProps {
    deal: DealParams | undefined;
    entityIdToName: Map<string, string>;
    businessDate: Date;
}

const DealDetails: React.FC<DealDetailsProps> = ({ deal, entityIdToName, businessDate }) => {
    const dealDetailsObjectArray = [
        {
            label: 'Deal Assignee',
            value: (
                <Tooltip
                    label={entityIdToName.get(cleanUUID(deal?.adminEntity?.id ?? ''))}
                >
                    <Avatar radius="xl" color="blue">
                        {getInitials(
                            entityIdToName.get(cleanUUID(deal?.adminEntity?.id ?? ''))
                        )}
                    </Avatar>
                </Tooltip>
            ),
        },
        {
            label: 'Borrowers',
            value: deal?.borrowers?.map((e: { id: string }, index: number) => (
                <Tooltip
                    label={entityIdToName.get(cleanUUID(e.id))}
                    key={'dealtooltip-' + index}
                >
                    <Avatar radius="xl" color="blue">
                        {getInitials(entityIdToName.get(cleanUUID(e.id)))}
                    </Avatar>
                </Tooltip>
            )) as string | undefined,
        },
        {
            label: 'Total Amount',
            value: formatNumberToCurrency(Number(deal?.amount), deal?.currency ?? 'USD'),
        },
        {
            label: 'Currency',
            value: deal?.currency,
        },
        {
            label: 'Start Date',
            value: formatDateToUTC(
                deal?.startDate ? stringToDate(deal?.startDate) : businessDate
            ),
        },
        {
            label: 'End Date',
            value: formatDateToUTC(
                deal?.maturity ? stringToDate(deal?.maturity) : businessDate
            ),
        },
        {
            label: 'Deal Ref ID',
            value: deal?.id,
        },
    ];

    return (
        <Stack w='100%' pt='12px' className='sectionDetailBody noHeader'>
            <DetailSection detailObjArr={dealDetailsObjectArray} columnCount={4} />
        </Stack>
    );
};

export default DealDetails;

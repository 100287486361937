import { Group, SegmentedControl, Stack, SegmentedControlItem } from '@mantine/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type TabItem = {
  route: string;
  label: string;
  active?: boolean;  // Add the 'active' property
  stopNavigation?: boolean;
};

type Props = {
  tabs: Record<string, TabItem>;
  onTabChange?: (selectedTab: string) => void; // Make it optional
};

export default function SegmentControlUpdated({ tabs, onTabChange }: Props) {
  const navigate = useNavigate();

  const tabData: SegmentedControlItem[] = Object.entries(tabs).map(([, value]) => ({
    value: value.route,
    label: value.label,
  }));

  const initialActiveTab = Object.values(tabs).find((tab) => tab?.active)?.route || Object.values(tabs)[0]?.route || '';
  const [activeTabRoute, setActiveTabRoute] = useState(initialActiveTab);

  function changeTab(e: string): void {
    setActiveTabRoute(e);

    if (onTabChange) {
      onTabChange(e); // Call only if onTabChange is provided
    }

    if (tabs[e]?.stopNavigation) return;

    navigate(e);
  }

  return (
    <Stack align="stretch" w="100%">
      <Group noWrap w="100%">
        <SegmentedControl
          size="lg"
          onChange={changeTab}
          value={tabData.some((tab) => tab.value === activeTabRoute) ? activeTabRoute : tabData[0]?.value}
          data={tabData}
          classNames={{
            root: 'segmented-root',
            label: 'segmented-label',
            indicator: 'segmented-indicator',
            controlActive: 'segmented-control-active',
            control: 'segmented-control',
          }}
        />

      </Group>
    </Stack>
  );
}

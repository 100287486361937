import { Table, Text, Radio } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'

interface Props {
  contacts: ContactParams[]
}

export default function ContactTable({ contacts }: Props) {
  if (!contacts || contacts.length === 0) {
    return null
  }
  return (
    <Table className="contact-table">
      <thead>
        <tr>
          <th></th>
          <th><Text>Name</Text></th>
          <th><Text>Email</Text></th>
          <th><Text>Phone</Text></th>
          <th><Text>Fax</Text></th>
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact, index) => (
          <tr key={index}>
            <td><Radio value={contact.id} /></td>
            <td><Text>{contact.name}</Text></td>
            <td><Text>{contact.email}</Text></td>
            <td><Text>{contact.phoneNumber}</Text></td>
            <td><Text>{contact.faxNumber}</Text></td>
          </tr>
        ))}
      </tbody>
    </Table>

  )
}

import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getMasterTrades,
  addMasterTrade,
  editMasterTrade,
  getMasterTrade,
  submitMasterTrade,
  rejectMasterTrade,
  approveMasterTrade,
  deleteMasterTrade,
  getLockedMasterTrades,
  startLoadingMasterTrades,
  stopLoadingMasterTrades,
} from 'app/state/ducks/master-trades/actions'

import { MasterTradeParams } from 'app/models/master-trade-params'

export const saveMasterTrade =
  (savedMasterTrade: MasterTradeParams, masterTrade?: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    
    if (masterTrade) {
      return client
        .put(`${apiServerUrl}/api/draft-master-trades`, savedMasterTrade)
        .then(masterTrade => {
          dispatch(editMasterTrade(masterTrade.data))
          return { success: true, payload: masterTrade.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/draft-master-trades`, savedMasterTrade)
        .then(masterTrade => {
          dispatch(addMasterTrade(masterTrade.data))
          return { success: true, payload: masterTrade.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
  }

const loadApprovedMasterTrades = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-master-trades/`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadDraftMasterTrades = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/draft-master-trades`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadSubmittedMasterTrades = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/submitted-master-trades`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllMasterTrades = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingMasterTrades())
    const [draftMasterTrades, submittedMasterTrades, approvedMasterTrades] = await Promise.all([
      loadDraftMasterTrades(),
      loadSubmittedMasterTrades(),
      loadApprovedMasterTrades(),
    ])

    const subMasterTrades = submittedMasterTrades.map((a: { masterTrade: any }) => ({
      ...a.masterTrade,
      status: 'Submitted',
    }))

    const allMasterTrades = draftMasterTrades
      .map((masterTrade: MasterTradeParams) => ({ ...masterTrade, status: 'Draft' }))
      .concat(subMasterTrades)
      .concat(
        approvedMasterTrades.map((masterTrade: MasterTradeParams) => ({ ...masterTrade, status: 'Approved' })),
      )
    return dispatch(getMasterTrades(allMasterTrades))
  } catch (error) {
    console.log('THUNK ERROR: ', error)
  } finally {
    dispatch(stopLoadingMasterTrades())
  }
}

export const loadOneMasterTrade =
  (masterTradeId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower =
      status.toLowerCase() == 'terminated' ? 'locked' : status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-master-trades/one?id=${masterTradeId}`)
      .then(masterTrades => {
        if (statusLower === 'submitted' || statusLower === 'locked') {
          return dispatch(getMasterTrade({ ...masterTrades.data.masterTrade, status: status }))
        }
        return dispatch(getMasterTrade({ ...masterTrades.data, status: status }))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const setOneMasterTrade = (masterTrade: any) => async (dispatch: Dispatch) => {
  dispatch(getMasterTrade({ ...masterTrade }))
}

export const submitForApproval =
  (masterTrade: MasterTradeParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-master-trades/submit`, { id: masterTrade.id })
      .then(response => {
        dispatch(submitMasterTrade(response.data))
        return { success: true, payload: masterTrade }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSubmittedMasterTrade =
  (masterTrade: MasterTradeParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-master-trades/reject`, { id: masterTrade.id })
      .then(response => {
        dispatch(rejectMasterTrade(response.data))
        return { success: true, payload: response.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSubmittedMasterTrade =
  (masterTrade: MasterTradeParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-master-trades/approve`, { id: masterTrade.id })
      .then(response => {
        dispatch(approveMasterTrade(response.data))
        return { success: true, payload: masterTrade }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteDraftMasterTrade =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/draft-master-trades?ids=${ids}`)
      .then(response => {
        return dispatch(deleteMasterTrade(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }

export const loadLockedMasterTrades = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/locked-master-trades`)
    .then(response => {
      const lockedMasterTrades = response.data.map(
        (a: { masterTrade: any; locked: string }) => ({
          ...a.masterTrade,
          status: 'Terminated',
          terminationDate: a.locked,
        }),
      )

      return dispatch(getLockedMasterTrades(lockedMasterTrades))
    })
    .catch(error => {
      console.log('THUNK ERROR: ', error)
    })
}

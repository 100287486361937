import { Text, Stack } from '@mantine/core'
import { PaymentParams } from 'app/models/payments-params'
import React from 'react'
import { ReceiversTable } from './receives-table/receivers-table'
import { SenderTable } from './senders-table/senders-table'
interface Props {
  payment: PaymentParams
  closeDrawer: () => void
  isDrawer?: boolean
}

export function PaymentLenderAllocationTable({
  payment,
  closeDrawer,
  isDrawer = false,
}: Props) {
  return (
    <Stack w='100%' className='sectionRemoveGap sectionBody'>
      <Stack w='100%' className='sectionDetailBodyDrawer'>
        <Text className='sectionDetailBlueHeader'>Lender Allocations</Text>
        {payment.payment.transactionType === 'NewBorrowingTransaction' ? <SenderTable payment={payment} isDrawer={isDrawer} /> : <ReceiversTable payment={payment} closeDrawer={closeDrawer} isDrawer={isDrawer}/>}
      </Stack>
    </Stack>


  )
}

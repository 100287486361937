import { LendersPositionState } from './reducer'
export const getLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.lendersPositions.data
export const getMyCompanyLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.myCompanyLendersPositions?.data
export const getFacilityLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.facilityLendersPositions.data
export const getLoanLendersPositions = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.loanLendersPositions.data
export const getFacilityLendersPositionsWithLoading = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.facilityLendersPositions
export const getLoanLendersPositionsWithLoading = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.loanLendersPositions
export const getLenderPositionsLoader = (state: {
  lendersPositions: LendersPositionState
}) => state.lendersPositions.lendersPositions.isLoading
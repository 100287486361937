import { Stack } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'
import MainTable from 'app/views/components/Table/MainTable'
import { LenderHistory, ProRataData } from 'app/models/lenders-position-params'
import { getColumnDefs } from './table-columnDefs'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getLoan } from 'app/state/ducks/loans/selectors'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import LenderInterestComponent from './lender-interest-component'
import { LenderTransactionTypeOptions } from 'app/models/dropdown-options'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'


interface Props {
    lendersPosition: any
    contacts?: ContactParams[]
    changeTabToWireInstructions?: any
}


export default function HistoryAccordion({
    lendersPosition,
}: Props) {
    const { contractType } = useParams()
    const loan = useSelector(getLoan)
    const facility = useSelector(getFacility)
    const currency = contractType === 'loan' ? loan?.currency ?? 'USD' : facility?.baseCurrency ?? 'USD'

    function cleanLenderHistoryData(
        filteredLenderHistory: ProRataData[],
    ): LenderHistory[] {
        return (
            filteredLenderHistory.map((proRata, index) => {
                const transType = LenderTransactionTypeOptions.find((option) => option.value === proRata.transactionType);
                return {
                    effectiveDate: proRata.effectiveDate,
                    transactionType: transType?.label ?? "",
                    amount: amount(proRata, index, transType?.value),
                    newBalance: Number(proRata.amount),
                };
            }) ?? []
        );
    }

    const amount = (proRata: ProRataData, index: number, type: string | undefined) => {
        if (index == 0) {
            return Number(proRata.amount)
        } else if (type === 'InterestPaydown') {
            return Number(proRata.interestPaid)
        } else {
            return Number(proRata.amount) - Number(lendersPosition.proRatas[index - 1].amount)
        }
    }


    return (
        <Stack w="100%" className='sectionRemoveGap'>
            <SectionTitle sm={true}>History</SectionTitle>
            <Stack w='100%' className='sectionRemoveGap sectionBody'>
                <Stack w='100%' mt='30px' className='sectionDetailBody'>
                    {contractType === 'loans' && (
                        <LenderInterestComponent
                            lendersPosition={lendersPosition}
                            currency={currency}
                        />
                    )}
                    <MainTable
                        minHeight='0xp'
                        tableName='Lender History'
                        border={true}
                        csvExportEnabled={true}
                        columnDefs={getColumnDefs(currency)}
                        data={cleanLenderHistoryData(lendersPosition.proRatas)}
                        enableTopToolbar={false}
                        headerBackgroundColor='#F0EEEE'
                        setRow={() => {
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}
import React from 'react';
import { Box } from '@mantine/core';
import { EntityTypeParams } from 'app/models/entity-type-params';
import EntityPair from './entity-type-pair';

interface EntityListProps {
    entities: EntityTypeParams[];
    onEditEntity: (entity: EntityTypeParams) => void;
    expandedEntityId: string | null;
    setExpandedEntityId: React.Dispatch<React.SetStateAction<string | null>>;
    newEntity: EntityTypeParams;
}

const EntityList: React.FC<EntityListProps> = ({
    entities,
    onEditEntity,
    expandedEntityId,
    setExpandedEntityId,
    newEntity,
}) => {
    // Rearrange entities into pairs so that they can be displayed in alphabetical order by column first
    // Derive the number of rows needed
    const numRows = Math.ceil(entities.length / 2);
    const rearrangedEntities = [];

    // Reshuffle entities into column-first order
    for (let i = 0; i < numRows; i++) {
        rearrangedEntities.push(entities[i], entities[i + numRows]);
    }

    // Filter out any undefined items if the number of entities is odd
    const validEntities = rearrangedEntities.filter(Boolean);
    const entityPairs = [];
    for (let i = 0; i < validEntities.length; i = i + 2) {
        entityPairs.push(validEntities.slice(i, i + 2));
    }
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '10px',
            }}
        >
            {entityPairs.map((entityPair) => {
                const [entity1, entity2] = entityPair;
                return (
                    <EntityPair
                        key={entity1.id}
                        entity1={entity1}
                        isExpanded1={expandedEntityId === entity1.id}
                        onEdit={onEditEntity}
                        setExpandedEntityId={setExpandedEntityId}
                        newEntity1={newEntity}
                        entity2={entity2}
                        isExpanded2={expandedEntityId === entity2?.id}
                        newEntity2={newEntity}
                    />)
            }
            )}
        </Box>
    );
};

export default EntityList;

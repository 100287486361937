import { PaymentParams, ReceiverParams } from 'app/models/payments-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getReceivers } from 'app/state/ducks/payments/selectors'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import WireInstructionHoverCard from 'app/views/components/hover-cards/wire-instruction-hover-card'
import React, { useState } from 'react'
import { mapEntityIdsToNames } from 'app/utils/util-functions'
import { useDispatch, useSelector } from 'react-redux'
import AlertHoverCard from 'app/views/components/hover-cards/alert-hover-card'
import { receiverColDefs, ReceiverTableParams } from './receiver-column-defs'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import MainTable from 'app/views/components/Table/MainTable'
import { ReceiversActions } from './receiver-actions'
import { Switch, Text } from '@mantine/core'
import { changeFrontingOnPayment } from 'app/state/ducks/payments/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'

interface Props {
  payment: PaymentParams
  closeDrawer: () => void
  isDrawer?: boolean
}


export const ReceiversTable = ({ payment, closeDrawer, isDrawer=false }: Props) => {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const receivers = useSelector(getReceivers)
  const [fronting, setFronting] = useState(payment.payment.fronting)

  const changeFronting = async (value: boolean) => {
    setFronting(value)
    const response: any = await dispatch(changeFrontingOnPayment(payment, value))
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Fronting status Failed',
        message: response.payload ?? 'Failed to change fronting',
      })
      setFronting(!value)
      return
    }
    
    SuccessNotification({
      title: 'Successfully changed fronting status',
      message: 'Status is updated.',
    }, isDrawer)
  }

  function cleanDealData(
    filteredReceivers: ReceiverParams[],
  ): ReceiverTableParams[] {
    const entityIdToName = mapEntityIdsToNames(entities);

    return (
      filteredReceivers.map((lender) => {
        const lenderName = lender.lenderId?.id
          ? entityIdToName.get(cleanUUID(lender.lenderId.id)) ?? ''
          : '';

        return {
          lender: lenderName,
          wireInstruction: <WireInstructionHoverCard wireInstruction={lender.wireInstruction} />,
          amount: formatNumberToCurrency(Number(lender.amount), lender.wireInstruction.currency),
          alert: <AlertHoverCard entities={entities} lenderId={lender.lenderId.id} />,
          actions: <ReceiversActions lender={lender} closeDrawer={closeDrawer} payment={payment} />,
        };
      })
    );
  }

  return (
    <div style={{ padding: "0px 30px" }}>
      <MainTable
        minHeight={'100px'}
        tableName=''
        withPagination={false}
        csvExportEnabled={false}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        columnDefs={receiverColDefs}
        data={cleanDealData(receivers)}
        setRow={() => null} />
      <Switch
        pt="10px"
        labelPosition="right"
        label={<Text color='prime-purple' weight='600'>Fronting</Text>}
        size="lg"
        color='prime-purple'
        checked={fronting}
        onChange={() => { changeFronting(!fronting) }}
        value={fronting.toString()}
      />
    </div>
  )
}

import { Group, Stack } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { LendersPosition } from 'app/models/lenders-position-params'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { ContactParams } from 'app/models/contact-params'
import ContactsAccordion from './contacts-accordion'
import { WireInstructionsAccordion } from './wire-instructions-accordion'
import { EntityParams } from 'app/models/entity-params'
import HistoryAccordion from './history-accordion'
import MainDrawer from 'app/views/components/Drawer/MainDrawer'
import { getDeal } from 'app/state/ducks/deals/selectors'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  lendersPosition?: LendersPosition
}

export default function EditLendersPositionDrawer({
  open,
  setOpen,
  lendersPosition,
}: Props) {

  const entities = useSelector(getEntities)
  const [tab, setTab] = useState<string>('lenderAllocationsContacts')
  const [entity, setEntity] = useState<EntityParams | undefined>(undefined)
  const wireInstructions = useSelector(getWireInstructions)
  const contacts = useSelector(getContacts)
  const [entityContacts, setEntityContacts] = useState<ContactParams[]>([])
  const [entityWireInstructions, setEntityWireInstructions] = useState<
    WireInstructionParams[]
  >([])
  const deal = useSelector(getDeal)

  useEffect(() => {
    if (!lendersPosition || !entities) {
      return
    }
    setEntity(entities.find(entity => entity.id == lendersPosition.lender.id))
  }, [lendersPosition, entities])


  useEffect(() => {
    if (!lendersPosition) {
      return
    }
  }, [lendersPosition])

  useEffect(() => {
    if (!entity) {
      return
    }
    if (wireInstructions) {
      setEntityWireInstructions(
        wireInstructions.filter(
          instruction => cleanUUID(instruction.entity.id) == entity.id
        )
      )
    }
  }, [entity, wireInstructions])

  useEffect(() => {
    if (!entity) {
      return
    }
    if (contacts) {
      setEntityContacts(
        contacts.filter(contact => cleanUUID(contact.entity.id) == entity.id)
      )
    }
  }, [entity, contacts])

  const tabs: { [key in string]: { route: string; label: string; active: boolean; stopNavigation: boolean } } = {
    lenderAllocationsContacts: {
      route: 'lenderAllocationsContacts', label: 'CONTACTS', active: tab === 'lenderAllocationsContacts' ? true : false,
      stopNavigation: true
    },
    lenderAllocationsWireInstructions: {
      route: 'lenderAllocationsWireInstructions', label: 'WIRE INSTRUCTIONS', active: tab === 'lenderAllocationsWireInstructions' ? true : false,
      stopNavigation: true
    },
    lenderAllocationsHistory: {
      route: 'lenderAllocationsHistory', label: 'HISTORY', active: tab === 'lenderAllocationsHistory' ? true : false,
      stopNavigation: true
    },
  }

  const isDrawer = true

  return (
    <>
      <MainDrawer opened={open} setOpen={setOpen} titleText={deal?.dealName ?? ''} subTitle={entity?.entityName ?? ''}>
        <Group w="100%" position="apart" className='tabsGapsActivity' mt='20px'>
          <Group position="right">
            <SegmentControlUpdated tabs={tabs} onTabChange={(selectedTab) => setTab(selectedTab)} />
          </Group>
        </Group>
        <Stack justify="space-between" h="100%">
          <Stack spacing="lg">
            {tab === 'lenderAllocationsHistory' && (
              <HistoryAccordion
                contacts={entityContacts}
                lendersPosition={lendersPosition}
              />
            )}
            {tab === 'lenderAllocationsContacts' && (
              <ContactsAccordion
                contacts={entityContacts}
                lendersPosition={lendersPosition}
                changeTabToWireInstructions={() => setTab('lenderAllocationsWireInstructions')}
                isDrawer={isDrawer}
              />
            )}
            {tab === 'lenderAllocationsWireInstructions' && (
              <WireInstructionsAccordion
                wireInstructions={entityWireInstructions}
                lendersPosition={lendersPosition}
                closeDrawer={() => setOpen(false)}
              />
            )}
          </Stack>
        </Stack>
      </MainDrawer>
    </>
  )
}

import React from 'react';
import { Stack, Group, Text, Radio } from '@mantine/core';
import { TradeEntryEntitySearch } from './trade-entry-entity-search';
import { useSelector } from 'react-redux';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput';

interface Props {
  title: string;
  radio?: boolean;
  isBuyer: boolean;
  onBuyerSellerChange?: (value: any) => void;
  form: any
}

export function TradeEntryCounterParty({ title, radio, isBuyer, onBuyerSellerChange, form }: Props) {
  const entities = useSelector(getEntities)

  const onChange = (value?: string | null) => {
    if (!value) {
      form.setFieldValue(isBuyer ? 'buyerId' : 'sellerId', '')
      form.setFieldValue(isBuyer ? 'buyer' : 'seller', null)
    }
    const entity = entities.find((e) => e.id === value)
    form.setFieldValue(isBuyer ? 'buyerId' : 'sellerId', value)
    form.setFieldValue(isBuyer ? 'buyer' : 'seller', entity)
  }
  return (
    <Stack className="tradeEntryCounterpartiesSection">
      <Text w="25%" className="tradeEntryCounterpartiesTitle">
        {title}
      </Text>
      <Stack className="tradeEntryDealSectionStack"
      // style={{ border: "1px solid #B4B3B3", background: "#ede6e6", padding: "20px" }}
      >
        <Group noWrap className="tradeEntryCounterpartiesTitleSection">
          {radio && (
            <Radio.Group name="tradeEntryRadioButtons" onChange={onBuyerSellerChange} value={isBuyer ? 'buyer' : 'seller'}>
              <Group noWrap className="tradeEntryRadioButtonsGroup">
                <Radio
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Buy"
                  value="buyer"
                />
                <Radio
                  size="lg"
                  className="tradeEntryRadioButtons"
                  labelPosition="left"
                  label="Sell"
                  value="seller"
                />
              </Group>
            </Radio.Group>
          )}
          <TradeEntryEntitySearch onChange={onChange} value={isBuyer ? form.values.buyerId : form.values.sellerId} isRight={!radio} />

        </Group>
        <Stack className="tradeEntryCounterpartiesStack">
          <Group noWrap w="100%" className="dataEntrySubSection">
            <TextLabelInput label="Entity Name" > <Text className='newInput'>{isBuyer ? form.values.buyer?.entityName ?? '' : form.values.seller?.entityName ?? ''}</Text>
            </TextLabelInput>
          </Group>
          <Group noWrap className="tradeEntryCounterpartiesSubSections">
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="Entity Type" > <Text className='newInput'>{isBuyer ? form.values.buyer?.entityTypeName ?? '' : form.values.seller?.entityTypeName ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="MEI" > <Text className='newInput' >{isBuyer ? form.values.buyer?.mei ?? '' : form.values.seller?.mei ?? ''}</Text>
              </TextLabelInput>
            </Group>
          </Group>
          <Group noWrap className="tradeEntryCounterpartiesSubSections">
            <Group noWrap w="50%" className="dataEntrySubSection">
              <TextLabelInput label="Internal ID" > <Text className='newInput'>{isBuyer ? form.values.buyer?.internalId ?? '' : form.values.seller?.internalId ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="50%" className="dataEntrySubSection">

              <TextLabelInput label="LendOS ID" > <Text className='newInput'>{isBuyer ? '' : ''}</Text>
              </TextLabelInput>
            </Group>
          </Group>
        </Stack>
      </Stack>
    </Stack>
  );
}

import React from 'react';
import { Table } from '@mantine/core';
import { EntityParams } from 'app/models/entity-params';

type Props = {
    data: EntityParams[];
    handleSelectChange: (value: string) => void;
    searchValue: string;
};

export function TradeEntryEntityTable({ data, searchValue, handleSelectChange }: Props) {
    // Helper function to highlight matching text
    const highlightText = (text: string) => {
        if (!searchValue) return text; // Return original text if search value is empty

        const parts = text.split(new RegExp(`(${searchValue})`, 'gi')); // Split the text at the matching parts
        return parts.map((part, index) =>
            part.toLowerCase() === searchValue.toLowerCase() ? (
                <span key={index} className='tradeSearchResult'>{part}</span>
            ) : (
                part
            )
        );
    };

    return (
        <div className='tradeTableContainer'>
            <div className='tradeTableScroll'>
                <Table
                    highlightOnHover
                    className="tradeTable"
                >
                    <thead>
                        <tr>
                            <th>
                                Entity Name
                            </th>
                            <th>
                                Entity Type
                            </th>
                            <th>
                                Mei
                            </th>
                            <th>
                                Internal ID
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((e) => (
                            <tr
                                key={e.id}
                                onClick={() => handleSelectChange(e.id ?? '')}
                            >
                                <td>{highlightText(e.entityName ?? '')}</td>
                                <td>{highlightText(e.entityTypeName ?? '')}</td>
                                <td>{highlightText(e.mei ?? '')}</td>
                                <td>{highlightText(e.internalId ?? '')}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>

    );
}

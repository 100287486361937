import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { SelectWithTable } from 'app/views/components/inputs/selects/CustomSelectWithTable';
import { TradeEntryEntityTable } from './trade-entry-entity-table';
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors';

type Props = {
  onChange: (value?: string | null) => void;
  value: string;
  isRight?: boolean;
};

export function TradeEntryEntitySearch({ onChange, value, isRight }: Props) {

  const entities = useSelector(getEntities);
  const entityTypes = useSelector(getEntityTypes)

  const entWithTypeName = entities.map((entity) => {
    entity.entityTypeName = entityTypes.find((type) => type.id === entity.entityType.id)?.name;
    return entity
  });

  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility
  const [searchValue, setSearchValue] = useState(value ?? ''); // State to control the search filter
  const [filteredEntities, setFilteredEntities] = useState(entWithTypeName); // State to store the filtered entities

  useEffect(() => {
    setFilteredEntities(entWithTypeName.filter((e) =>
      e.entityName.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.mei?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.internalId?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e.entityTypeName?.toLowerCase().includes(searchValue.toLowerCase())
    ))
  }, [searchValue, entities, entityTypes]);

  useEffect(() => {
    // if (filteredEntities.length === 1) {
    //   onChange(filteredEntities[0].id);
    // }
    if (filteredEntities.length > 1) {
      onChange(null);
    }
  }, [filteredEntities]);

  useEffect(() => {
    if (!value) {
      setSearchValue('');
    }
  }, [value])

  const handleSelectChange = (value: string) => {
    const selectedEntity = filteredEntities.find((e) => e.id === value);
    setSearchValue(selectedEntity?.entityName ?? ''); // Set search value to the selected entity name
    onChange(value); // Notify parent component of the selection
    setDropdownOpen(false); // Close dropdown after selection
  };


  return (
    <SelectWithTable
      searchValue={searchValue}
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      setSearchValue={setSearchValue}
      classNames={{ dropdown: isRight ? 'tradeEntrySelectEntityRight' : 'tradeEntrySelectEntityLeft' }}>
      <TradeEntryEntityTable data={filteredEntities} handleSelectChange={handleSelectChange} searchValue={searchValue} />
    </SelectWithTable>
  );
}

import { Text, Stack, } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { DetailSection } from 'app/views/components/detailSections/detail-section'
import { PaymentParams } from 'app/models/payments-params'
import { getSenders } from 'app/state/ducks/payments/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { PaymentTransactionType } from 'app/models/dropdown-options'
import { changeReceivedLenders } from 'app/state/ducks/payments/thunks'

interface Props {
    payment?: PaymentParams
    isDrawer?: boolean
}

export default function PaymentDetailsInfo({ payment, isDrawer=false }: Props) {
    const dispatch = useDispatch()
    const businessDate = useSelector(getBusinessDate)
    const senders = useSelector(getSenders)
    const [received, setReceived] = useState<boolean>(false)

    useEffect(() => {
        if (payment?.payment.transactionType === 'NewBorrowingTransaction') {
            return
        }
        if (senders.length == 0) {
            return
        }
        setReceived(senders[0].paymentReceived)
        if (payment?.id == '1') {
            console.log(businessDate)
            changeReceived()
        }
    }, [senders, payment])

    const changeReceived = async () => {
        if (!payment || senders.length == 0) {
            return
        }
        const response: any = await dispatch(changeReceivedLenders(payment, [{ lender: senders[0].lenderId, paymentReceived: !received }]))
        if (response.success === false) {
            ErrorNotification({
                title: 'Change Received status Failed',
                message: response.payload ?? 'Failed to change payment received status',
            }, isDrawer)
            return
        }

        SuccessNotification({
            title: 'Successfully changed payment received status',
            message: 'Status is updated.',
        }, isDrawer)
        setReceived(!received)
    }
    const paymentDetailsObjectArray = [
        {
            label: 'Facility name',
            value: payment?.payment.facilityName ?? '',
        },
        {
            label: 'Transaction type',
            value: payment?.payment.transactionType
                ? PaymentTransactionType.find(
                    p => p.value == payment.payment.transactionType
                )?.label ?? ''
                : '',
        }, {
            label: 'Amount',
            value: formatNumberToCurrency(Number(payment?.payment.amount), 'USD'),
        },
        {
            label: 'Currency',
            value: payment?.payment.currency ?? '',
        },
        {
            label: 'Effective Date',
            value: payment?.payment.effectiveDate ?? '',
        },
        {
            label: 'User',
            value: 'John Doe',
        }
    ]

    return (
        <Stack w='100%' className='sectionRemoveGap sectionBody'>
            <Stack w='100%' className='sectionDetailBodyDrawer'>
                <Text className='sectionDetailBlueHeader'>Payment Details</Text>
                <div style={{ paddingLeft: '30px' }}>
                    <DetailSection detailObjArr={paymentDetailsObjectArray} columnCount={3} />
                </div>
            </Stack>
        </Stack >
    )
}
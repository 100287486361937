import { LoanParams } from 'app/models/loan-params'
import { Group, NumberInput, Select, Stack, Text } from '@mantine/core'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import {
  paymentTypeOptions,
  strBooleanOptions,
} from 'app/models/dropdown-options'
import {
  PrincipalPaymentParams,
} from 'app/models/principal-payments-params'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import { FacilityParams } from 'app/models/facility-params'
import { LendersPosition } from 'app/models/lenders-position-params'

interface Props {
  loan: LoanParams;
  payment: undefined | PrincipalPaymentParams;
  facility?: FacilityParams;
  form: any
  lendersPositions: LendersPosition[]
}


export const UpdatePrincipalPaymentForm = ({
  loan,
  payment,
  facility,
  form,
  lendersPositions,
}: Props) => {
  const handleTypeChange = (value: string | null) => {
    form.setFieldValue('paymentType', value)
    form.setFieldValue('acceptedLenders', lendersPositions.map((lender: any) => lender.lender))

    form.setFieldValue('amount', 0);  
    form.setFieldValue('interestPaid', null); 

    if (value == 'FullPaymentPrincipal') {
      form.setFieldValue('amount', Number(loan.amount))
      form.setFieldValue('interestPaid', 'true')
    }
    if (value == 'InterestPayment') {
      form.setFieldValue('amount', Number(loan.accrualInterestMaturityDate))
      form.setFieldValue('interestPaid', 'true')
    }
  }

  return (
    <Stack spacing="xl">
      {payment && payment.status == 'Submitted' ? (
        <Text className="topFormErrorText">
          Payment has already been submitted
        </Text>
      ) : null}
      <Group noWrap>
        <CustomDatePicker
          errorMsg={form.errors.paymentDate}
          disabled={payment && payment.status == 'Submitted'}
          required
          label={'Effective Date'}
          date={form.values.paymentDate}
          setDate={(value: any) => {
            form.setFieldValue("activeLenders", lendersPositions.filter(lp => lp.proRatas && lp.proRatas.length > 0 && stringToDate(lp.proRatas[0].effectiveDate) <= form.values.paymentDate))
            form.setFieldValue('paymentDate', value)
          }}
          holidayCalendars={facility?.holidayCalendar ?? []}
        />
        <Select
          searchable
          disabled={payment && payment.status == 'Submitted'}
          withAsterisk
          label="Type"
          placeholder="Select Payment Type"
          w="100%"
          data={[...paymentTypeOptions]}
          {...form.getInputProps('paymentType')}
          onChange={value => handleTypeChange(value)}
        />
      </Group>

      <Group noWrap>
        <NumberInput
          disabled={
            (payment && payment.status == 'Submitted') ||
            ['FullPaymentPrincipal', 'InterestPayment'].includes(form.values.paymentType)
          }
          w="100%"
          label={form.values.paymentType === 'InterestPayment' ? 'Interest Amount' : 'Amount'}
          id="amount"
          placeholder="Enter Amount"
          parser={value => value.replace(/[^0-9.]/g, '')}
          precision={2}
          formatter={value =>
            !Number.isNaN(parseFloat(value))
              ? formatNumberToCurrency(Number(
                parseFloat(
                  value.replace(
                    /\B(?<!\d*)(?=(\d{3})+(?!\d))/g,
                    ','
                  )
                )
              ), loan?.currency ?? 'USD')
              : '$ 0'
          }
          {...form.getInputProps('amount')}
        />
        <Select
          searchable
          disabled={
            (payment && payment.status == 'Submitted') ||
            ['FullPaymentPrincipal', 'InterestPayment'].includes(form.values.paymentType)
          }
          label="With Accrued interest"
          placeholder="Select Yes or No"
          w="100%"
          data={[...strBooleanOptions]}
          {...form.getInputProps('interestPaid')}
        />
      </Group>
    </Stack>
  )
}


import { Group, Select, SelectProps } from "@mantine/core"
import { TextLabelInput } from "../TextLabelInput"
import IonIcon from "@reacticons/ionicons"

type Props = SelectProps & {
    label: string
}

export const NewSelectInput = ({ label, ...props }: Props) => (
    <Group noWrap className="dataEntrySubSection">
        <TextLabelInput label={label}>
            <Select
                classNames={{
                    input: 'newInput ',
                    wrapper: 'newInputWrapper',
                }}
                rightSection={<IonIcon name="chevron-down-outline"></IonIcon>}
                {...props}
            /></TextLabelInput>
    </Group>
)


import { Group, TextInput, TextInputProps } from "@mantine/core"
import { TextLabelInput } from "./TextLabelInput"

type Props = TextInputProps & {
    label: string
}

export const NewTextInput = ({ label, ...props }: Props) => (
    <Group noWrap className="dataEntrySubSection" >
        <TextLabelInput label={label}>
            <TextInput
                placeholder='Trade ID'
                classNames={{
                    input: 'newInput',
                    wrapper: 'newInputWrapper',
                }}
                {...props}
            />
        </TextLabelInput>
    </Group>
)


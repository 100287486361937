export const GET_MASTER_TRADES = 'master_trades/GET_MASTER_TRADES'
export const GET_MASTER_TRADE = 'master_trades/GET_MASTER_TRADE'
export const ADD_MASTER_TRADES = 'master_trades/ADD_MASTER_TRADES'
export const EDIT_MASTER_TRADES = 'master_trades/EDIT_MASTER_TRADES'
export const SUBMIT_MASTER_TRADE = 'master_trades/SUBMIT_MASTER_TRADE'
export const REJECT_MASTER_TRADE = 'master_trades/REJECT_MASTER_TRADE'
export const APPROVE_MASTER_TRADE = 'master_trades/APPROVE_MASTER_TRADE'
export const DELETE_MASTER_TRADE = 'master_trades/DELETE_MASTER_TRADE'
export const GET_LOCKED_MASTER_TRADES = 'master_trades/GET_LOCKED_MASTER_TRADES'
export const START_LOADING_MASTER_TRADES = 'master_trades/START_LOADING_MASTER_TRADES'
export const STOP_LOADING_MASTER_TRADES = 'master_trades/STOP_LOADING_MASTER_TRADES'

import { Group, Radio, Stack } from '@mantine/core';
import { LendersPosition } from 'app/models/lenders-position-params';
import { WireInstructionParams } from 'app/models/wire-instruction-params';
import { DetailSection } from 'app/views/components/detailSections/detail-section';
import cleanUUID from 'app/views/components/functions/cleanUUID';

interface Props {
  selectedWireInstructions: any;
  currency: string;
  setSelectedPaymentInstructions: any;
  allWireInstructions: WireInstructionParams[];
  lenderPosition: LendersPosition | LendersPosition;
}

export default function OneCurrency({
  selectedWireInstructions,
  currency,
  setSelectedPaymentInstructions,
  allWireInstructions,
}: Props) {
  const selectedWireInstruction = selectedWireInstructions[currency];

  function handleChange(currency: string, e: string): void {
    const wireInstruction = allWireInstructions.find((wi) => wi.id == e);
    setSelectedPaymentInstructions(currency, wireInstruction);
  }

  function getDetailObjArr(wireInstruction: WireInstructionParams) {
    return [
      { label: 'Currency', value: wireInstruction.currency },
      {
        label: 'Bank ABA #',
        value:
          wireInstruction.accountWithInstitutionBankAba && wireInstruction.accountWithInstitutionBankAba !== ''
            ? wireInstruction.accountWithInstitutionBankAba
            : '-',
      },
      {
        label: 'Account with Institution Name',
        value: wireInstruction.accountWithInstitutionName ?? '-',
      },
      {
        label: 'Beneficiary Customer Name',
        value: wireInstruction.beneficiaryCustomerName ?? '-',
      },
      {
        label: 'Beneficiary Account #',
        value: wireInstruction.beneficiaryAccountNumber ?? '-',
      },
      {
        label: 'Payment Reference',
        value: wireInstruction.paymentReference && wireInstruction.paymentReference !== ''
          ? wireInstruction.paymentReference
          : '-',
      },
    ];
  }

  return (
    <Radio.Group
      className="tradeEntryRadioButtons"
      size="lg"
      value={selectedWireInstruction?.id ?? ''}
      onChange={(e) => handleChange(currency, e)}
    >
      {allWireInstructions
        .filter((wireInstruction) => wireInstruction.currency === currency)
        .map((wireInstruction, index) => (
          <Stack mt='20px' mb='20px'>
            <Group noWrap w="100%">
              <Radio mb='25px' value={cleanUUID(wireInstruction.id ?? '')} />
              <DetailSection detailObjArr={getDetailObjArr(wireInstruction)} columnCount={3} sm={true} rowSpacing='10px' />
            </Group>
            {index < allWireInstructions.length - 1 && (
              <Group w="100%" h="1px" bg="#CED4DA"></Group>
            )}
          </Stack>
        ))}
    </Radio.Group>
  );
}

import { Group, Stack } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  children?: ReactNode
  sm?: boolean
  mb?: string
}

export default function SectionTitle({ children, sm }: Props) {
  return (
    <Stack w="100%">
      <Group noWrap w="100%" className={sm ?'sectionTitleSM' : 'sectionTitle'}>
        {children ?? ''}
      </Group>
    </Stack>
  )

}
import * as types from './types'

export const getLendersPositions = (payload: any) => ({
  type: types.GET_LENDERS_POSITIONS,
  payload,
})

export const getMyCompanyLendersPositions = (payload: any) => ({
  type: types.GET_MY_COMPANY_LENDERS_POSITIONS,
  payload,
})

export const getLendersPositionsByFacility = (payload: any) => ({
  type: types.GET_LENDERS_POSITIONS_BY_FACILITY,
  payload,
})

export const getLendersPositionsByLoan = (payload: any) => ({
  type: types.GET_LENDERS_POSITIONS_BY_LOAN,
  payload,
})

export const changeLendersForFacility = (payload: any) => ({
  type: types.CHANGE_LENDERS_FOR_FACILITY,
  payload,
})

export const changeLendersForLoan = (payload: any) => ({
  type: types.CHANGE_LENDERS_FOR_LOAN,
  payload,
})

export const editLendersPosition = (payload: any) => ({
  type: types.EDIT_LENDERS_POSITIONS,
  payload,
})

export const deleteLendersPositions = (payload: any) => ({
  type: types.DELETE_LENDERS_POSITIONS,
  payload,
})

export const resetFacilityLendersPositions = () => ({
  type: types.RESET_FACILITY_LENDERS_POSITIONS,
  payload: null,
})

export const startLoadingLendersPositions = () => ({
  type: types.START_LOADING_LENDERS_POSITIONS,
  payload: null,
})

export const startLoadingFacilityLendersPositions = () => ({
  type: types.START_LOADING_FACILITY_LENDERS_POSITIONS,
  payload: null,
})

export const startLoadingLoanLendersPositions = () => ({
  type: types.START_LOADING_LOAN_LENDERS_POSITIONS,
  payload: null,
})

export const stopLoadingLendersPositions = () => ({
  type: types.STOP_LOADING_LENDERS_POSITIONS,
  payload: null,
})

export const stopLoadingFacilityLendersPositions = () => ({
  type: types.STOP_LOADING_FACILITY_LENDERS_POSITIONS,
  payload: null,
})

export const stopLoadingLoanLendersPositions = () => ({
  type: types.STOP_LOADING_LOAN_LENDERS_POSITIONS,
  payload: null,
})

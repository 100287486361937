export enum EntityTypes {
  EntityName = 'EntityName',
  MEI = 'MEI',
  InstitutionType = 'InstitutionType',
  KycType = 'KycType',
  Address = 'Address',
  AddressLine2 = 'AddressLine2',
  City = 'City',
  State = 'State',
  PostalCode = 'PostalCode',
  Parent = 'Parent',
  ParentMEI = 'ParentMEI',
  FundManagerName = 'FundManagerName',
  FundManagerMEI = 'FundManagerMEI',
  LEI = 'LEI',
  CEI = 'CEI',
  NAICCode = 'NAICCode',
  CRN = 'CRN',
  EIN = 'EIN',
  GIIN = 'GIIN',
  EntityReference = 'EntityReference',
  UsTaxFormType = 'UsTaxFormType',
  TaxFormStatus = 'TaxFormStatus',
  KycStatus = 'KycStatus',
  CountryOfIncorporation = 'CountryOfIncorporation',
  CountryOfTaxResidence = 'CountryOfTaxResidence',
  CountryRegion = 'CountryRegion',
}

export type EntityTypeParams = {
  id?: string
  name: string
  mandatoryFields: EntityTypes[]
  isActive: boolean
  agencyAdmin?: string
}

import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface MasterTradeState {
  masterTrades: any[]
  masterTrade?: any
  lockedMasterTrades: any[]
  isLoading: boolean
}

const initialState: MasterTradeState = {
  masterTrades: [],
  masterTrade: undefined,
  lockedMasterTrades: [],
  isLoading: false,
}

const masterTradesReducer = createReducer(initialState)({
  [types.GET_MASTER_TRADES]: (state: any, payload: any) => {
    return { ...state, masterTrades: payload.payload }
  },
  [types.GET_MASTER_TRADE]: (state: any, payload: any) => {
    return { ...state, masterTrade: payload.payload }
  },
  [types.ADD_MASTER_TRADES]: (state: any, payload: any) => {
    return {
      ...state,
      masterTrades: [{ ...payload.payload, status: 'Draft' }, ...state.masterTrades],
    }
  },
  [types.EDIT_MASTER_TRADES]: (state: any, payload: any) => {
    const index = state.masterTrades.findIndex(
      (masterTrade: { id: any }) => masterTrade.id === payload.payload.id
    )
    const newArray = [...state.masterTrades]
    newArray[index] = { ...payload.payload, status: 'Draft' }
    if (state?.masterTrade?.id === payload?.payload?.id) {
      return {
        ...state,
        masterTrades: newArray,
        masterTrade: { ...payload.payload, status: 'Draft' },
      }
    }
    return {
      ...state,
      masterTrades: newArray,
    }
  },
  [types.GET_LOCKED_MASTER_TRADES]: (state: any, payload: any) => {
    return { ...state, lockedMasterTrades: payload.payload }
  },
  [types.SUBMIT_MASTER_TRADE]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.masterTrades.findIndex(
      (masterTrade: { id: any }) => masterTrade.id === payload.payload.id
    )
    const newArray = [...state.masterTrades]
    newArray[index] = { ...newArray[index], status: 'Submitted' }
    if (state?.masterTrade?.id === payload?.payload?.id) {
      return {
        ...state,
        masterTrade: { ...state.masterTrade, status: 'Submitted' },
        masterTrades: newArray,
      }
    }
    return {
      ...state,
      masterTrades: newArray,
    }
  },
  [types.REJECT_MASTER_TRADE]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.masterTrades.findIndex(
      (masterTrade: { id: any }) => masterTrade.id === payload.payload.id
    )
    const newArray = [...state.masterTrades]
    newArray[index] = { ...newArray[index], status: 'Draft' }
    if (state?.masterTrade?.id === payload?.payload?.id) {
      return {
        ...state,
        masterTrade: { ...state.masterTrade, status: 'Draft' },
        masterTrades: newArray,
      }
    }
    return {
      ...state,
      masterTrades: newArray,
    }
  },
  [types.APPROVE_MASTER_TRADE]: (state: any, payload: any) => {
    if (payload.payload.error) {
      return state
    }
    const index = state.masterTrades.findIndex(
      (masterTrade: { id: any }) => masterTrade.id === payload.payload.id
    )
    const newArray = [...state.masterTrades]
    newArray[index] = { ...newArray[index], status: 'Approved' }
    if (state?.masterTrade?.id === payload?.payload?.id) {
      return {
        ...state,
        masterTrade: { ...state.masterTrade, status: 'Approved' },
        masterTrades: newArray,
      }
    }
    return {
      ...state,
      masterTrades: newArray,
    }
  },
  [types.DELETE_MASTER_TRADE]: (state: any, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      masterTrades: state.masterTrades.filter((masterTrade: any) => !deletedIds.includes(masterTrade.id)),
      masterTrade:
        state.masterTrade && deletedIds.includes(state.masterTrade.id) ? null : state.masterTrade,
    }
  },
  [types.START_LOADING_MASTER_TRADES]: (state: MasterTradeState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_MASTER_TRADES]: (state: MasterTradeState) => {
    return { ...state, isLoading: false }
  },
})

export default masterTradesReducer

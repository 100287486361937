import * as types from './types'

export const getMasterTrades = (payload: any) => ({
  type: types.GET_MASTER_TRADES,
  payload,
})

export const getMasterTrade = (payload: any) => ({
  type: types.GET_MASTER_TRADE,
  payload,
})

export const addMasterTrade = (payload: any) => ({
  type: types.ADD_MASTER_TRADES,
  payload,
})

export const editMasterTrade = (payload: any) => ({
  type: types.EDIT_MASTER_TRADES,
  payload,
})

export const submitMasterTrade = (payload: any) => ({
  type: types.SUBMIT_MASTER_TRADE,
  payload,
})

export const rejectMasterTrade = (payload: any) => ({
  type: types.REJECT_MASTER_TRADE,
  payload,
})

export const approveMasterTrade = (payload: any) => ({
  type: types.APPROVE_MASTER_TRADE,
  payload,
})

export const deleteMasterTrade = (payload: any) => ({
  type: types.DELETE_MASTER_TRADE,
  payload,
})

export const getLockedMasterTrades = (payload: any) => ({
  type: types.GET_LOCKED_MASTER_TRADES,
  payload,
})

export const startLoadingMasterTrades = () => ({
  type: types.START_LOADING_MASTER_TRADES,
  payload: null,
})

export const stopLoadingMasterTrades = () => ({
  type: types.STOP_LOADING_MASTER_TRADES,
  payload: null,
})


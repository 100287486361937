import React, { useEffect, useState } from 'react';
import { Stack } from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoanParams } from 'app/models/loan-params';
import { DetailSection } from 'app/views/components/detailSections/detail-section';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors';
import { getFacility } from 'app/state/ducks/facilities/selectors';
import { getBusinessDate } from 'app/state/ducks/business-date/selectors';
import { getAmountWithInterest } from 'app/state/ducks/principal-payment/thunks';
import { getLoanDetailsObjectArray } from './loan-details';
import { formatDateToUTC } from 'app/utils/util-functions';

interface AmountWithInterest {
  amount: number;
  interest: number;
}

interface DetailPanelProps {
  row: LoanParams;
  isExpanded: boolean;
}

const LoanDetailPanel: React.FC<DetailPanelProps> = ({ row, isExpanded }) => {
  const navigate = useNavigate();
  const entities = useSelector(getEntities);
  const allIndexes = useSelector(getIndexRateOptions);
  const facility = useSelector(getFacility);
  const businessDate = useSelector(getBusinessDate);
  const iro = facility?.iroValues?.find((iro) => iro?.indexOption?.id === row?.indexOption?.id);

  const [currentAmountWithInterest, setCurrentAmountWithInterest] = useState<AmountWithInterest | undefined>();
  const [loanDetailsObjectArray, setLoanDetailsObjectArray] = useState<any[]>([]);

  const precalculate = async () => {
    try {
      const currentDate = await getAmountWithInterest({
        amount: null,
        paymentDate: formatDateToUTC(businessDate),
        interestPaid: true,
        acceptedLenders: null,
        loanId: { id: row.id, admin: row.agencyAdmin },
      });
      setCurrentAmountWithInterest(currentDate.payload);
    } catch (error) {
      console.error('Error fetching amount with interest:', error);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      precalculate();
    }
  }, [isExpanded, row, businessDate]);

  useEffect(() => {
    setLoanDetailsObjectArray(
      getLoanDetailsObjectArray({
        row,
        entities,
        allIndexes,
        facility,
        iro,
        currentAmountWithInterest,
        navigate,
      })
    );
  }, [row, entities, allIndexes, facility, iro, currentAmountWithInterest, navigate]);

  return (
    <Stack w="100%" pt="12px" className="sectionDetailBody noHeader">
      <DetailSection detailObjArr={loanDetailsObjectArray} columnCount={4} />
    </Stack>
  );
};

export default LoanDetailPanel;

import { Stack, Text } from '@mantine/core'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { useEffect, useState } from 'react'
import OneCurrency from './one-currency'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useDispatch } from 'react-redux'
import { updateLendersPosition } from 'app/state/ducks/lenders-positions/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { useSetState } from '@mantine/hooks'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'

interface Props {
  lendersPosition: any
  wireInstructions: WireInstructionParams[]
  closeDrawer: any
}

export function WireInstructionsAccordion({
  wireInstructions,
  lendersPosition,
  closeDrawer,
}: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);

  const [selectedWireInstructions, setSelectedWireInstructions] = useSetState({})

  const wiSet = wireInstructions.reduce<Record<string, WireInstructionParams[]>>((acc, instruction) => {
    // Check if the currency key already exists in the accumulator
    if (!acc[instruction.currency]) {
      acc[instruction.currency] = []; // Initialize an empty array for the currency
    }
    // Add the current instruction to the respective currency array
    acc[instruction.currency].push(instruction);

    return acc; // Return the accumulator for the next iteration
  }, {});

  useEffect(() => {
    lendersPosition.wireInstructions.forEach(({ id }: any) => {
      const wireInstruction = wireInstructions.find(instruction => instruction.id === id);

      if (wireInstruction) {
        setSelectedWireInstructions({ [wireInstruction.currency]: wireInstruction })
      }
    });

  }, [lendersPosition, wireInstructions]);

  const changeWireInstruction = (
    currency: string,
    selectedWire: WireInstructionParams | undefined
  ) => {
    setSelectedWireInstructions({ [currency]: selectedWire })
  }

  const saveWireInstructions = async () => {
    setLoading(true)
    lendersPosition.wireInstructions = [
      ...Object.values(selectedWireInstructions),
    ]
      .filter(wireInstructions => wireInstructions)
      .map((wireInstruction: any) => ({
        id: wireInstruction?.id ?? '',
        admin: wireInstruction?.public ?? '',
      }))
    const response: any = await dispatch(updateLendersPosition(lendersPosition))

    setLoading(false)
    if (!response.success) {
      ErrorNotification({
        title: 'Lender Position Updated Failed',
        message: response?.payload ?? 'Check your input and try again',
      })
      return
    }
    SuccessNotification({
      title: 'Wire Instructions Updated',
      message: 'You can also set admin and credit contact',
    })
    closeDrawer()
  }

  return (
    <Stack w="100%" className='sectionRemoveGap'>
      <SectionTitle sm={true}>Wire Instructions</SectionTitle>
      <Stack w='100%' className='sectionRemoveGap sectionBody'>
        <Stack w='100%' className='sectionDetailBody'>
          {Object.entries(wiSet).map(([currency, instructions]) => (
            <div key={currency}>
              {/* Display the currency as a header */}
              <Text className='sectionDetailHeaderDrawer'>{currency + ' Instructions'}</Text>

              {/* Render the OneCurrency component for each currency */}
              <OneCurrency
                selectedWireInstructions={selectedWireInstructions}
                key={currency}
                lenderPosition={lendersPosition}
                currency={currency}
                setSelectedPaymentInstructions={changeWireInstruction}
                allWireInstructions={instructions} // Pass the array of instructions for this currency
              />
            </div>
          ))}
        </Stack>
      </Stack>
      <PrimaryButton text="Save" onClick={() => saveWireInstructions()} loading={loading} />
    </Stack>

  )
}

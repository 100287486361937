import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  addLoan,
  editLoan,
  submitLoan,
  approveLoan,
  rejectLoan,
  getApprovedLoans,
  getAllApprovedLoans,
  deleteLoan,
  getLoanHistory,
  getLoan,
  getFacilityLoans,
  getAllLoans,
  getLockedLoansByFacility,
  startLoadingFacilityLoans,
  stopLoadingFacilityLoans,
} from 'app/state/ducks/loans/actions'
import { DraftLoanParams, LoanParams } from 'app/models/loan-params'

export const saveLoan =
  (savedLoan: DraftLoanParams, loan?: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const updated_savedLoan: DraftLoanParams = {
      ...savedLoan,
    }
    if (loan) {
      return client
        .put(`${apiServerUrl}/api/draft-loans`, updated_savedLoan)
        .then(response => {
          const payload = dispatch(editLoan(response))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }

    return client
      .post(`${apiServerUrl}/api/draft-loans`, updated_savedLoan)
      .then(response => {
        const payload = dispatch(addLoan(response))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadOneLoan =
  (loanId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(`${apiServerUrl}/api/${statusLower}-loans/one?id=${loanId}`)
      .then(loan => {
        if (statusLower === 'submitted') {
          return dispatch(getLoan({ ...loan.data.loan, status: status }))
        }
        return dispatch(getLoan({ ...loan.data, status: status }))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadLoanId = (loanId: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/approved-loans/one?id=${loanId}`)
    .then(response => {
      return dispatch(getApprovedLoans(response.data))
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const loadLoans = (facilityId: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(`${apiServerUrl}/api/approved-loans/facility?id=${facilityId}`)
    .then(response => {
      return dispatch(getApprovedLoans(response.data))
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadApprovedLoans = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/approved-loans${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllApprovedLoans = () => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/approved-loans`)
    .then(response => {
      return dispatch(getAllApprovedLoans(response.data))
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadDraftLoans = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/draft-loans${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadSubmittedLoans = async (facilityId?: string) => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(
      `${apiServerUrl}/api/submitted-loans${
        facilityId ? `/facility?id=${facilityId}` : ''
      }`,
    )
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadLockedLoans =
  (facilityId?: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return await client
      .get(
        `${apiServerUrl}/api/locked-loans${
          facilityId ? `/facility?id=${facilityId}` : ''
        }`,
      )
      .then(response => {
        const reshapedData = response.data.map((item: any) => ({
          ...item.loan,
          status: 'Locked',
        }))
        dispatch(getLockedLoansByFacility(reshapedData))
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }

export const submitForApproval = (loan: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/draft-loans/submit`, { id: loan.id })
    .then(response => {
      const payload = dispatch(submitLoan(response.data))
      return { success: true, payload: payload.payload }
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const approveSelectedLoan =
  (loan: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loans/approve`, { id: loan.id })
      .then(response => {
        dispatch(approveLoan(response.data))
        return { success: true }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedLoan = (loan: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-loans/reject`, { id: loan.id })
    .then(response => {
      const payload = dispatch(rejectLoan(response.data))
      return { success: true, payload: payload.payload }
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const deleteSelectedLoan = (loan: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/draft-loans?ids[]=${loan.id}`)
    .then(loan => {
      dispatch(deleteLoan(loan.data))
      return { success: true, payload: loan.data }
    })
    .catch(e => {
      return { success: false, payload: e.response.data.error }
    })
}

export const loadAllLoans =
  (facilityId?: string) => async (dispatch: Dispatch) => {
    if (facilityId) {
      dispatch(startLoadingFacilityLoans())
    }

    try {
      let all_loans: LoanParams[] = []

      const [draftLoans, submittedLoans, loans] = await Promise.all([
        loadDraftLoans(facilityId),
        loadSubmittedLoans(facilityId),
        loadApprovedLoans(facilityId),
      ])

      const arr = submittedLoans.map((a: { loan: any }) => a.loan)
      const sub_loans = arr.map((a: any) => ({ ...a, status: 'Submitted' }))

      all_loans = draftLoans
        .map((loan: any) => ({ ...loan, status: 'Draft' }))
        .concat(sub_loans)
        .concat(loans.map((loan: any) => ({ ...loan, status: 'Approved' })))

      if (facilityId) {
        return dispatch(getFacilityLoans(all_loans))
      } else {
        return dispatch(getAllLoans(all_loans))
      }
    } catch (error) {
      console.log('THUNK ERROR: ', error)
    } finally {
      if (facilityId) {
        dispatch(stopLoadingFacilityLoans())
      }
    }
  }

export const loadLoanHistory =
  (loanId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/approved-loans/history?id=${loanId}`)
      .then(response => {
        return dispatch(getLoanHistory(response.data))
      })
      .catch(error => {
        console.log('THUNK ERROR: ', error)
      })
  }

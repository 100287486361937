export const GET_LENDERS_POSITIONS = 'lenderspositions/GET_LENDERS_POSITIONS'
export const GET_MY_COMPANY_LENDERS_POSITIONS = 'lenderspositions/GET_MY_COMPANY_LENDERS_POSITIONS'
export const GET_LENDERS_POSITIONS_BY_FACILITY =
  'lenderspositions/GET_LENDERS_POSITIONS_BY_FACILITY'
export const GET_LENDERS_POSITIONS_BY_LOAN =
  'lenderspositions/GET_LENDERS_POSITIONS_BY_LOAN'
export const EDIT_LENDERS_POSITIONS = 'lenderspositions/EDIT_LENDERS_POSITIONS'
export const DELETE_LENDERS_POSITIONS =
  'lenderspositions/DELETE_LENDERS_POSITIONS'
export const CHANGE_LENDERS_FOR_FACILITY =
  'lenderspositions/CHANGE_LENDERS_FOR_FACILITY'
export const RESET_FACILITY_LENDERS_POSITIONS =
  'lenderspositions/RESET_FACILITY_LENDERS_POSITIONS'
export const START_LOADING_LENDERS_POSITIONS =
  'lenderspositions/START_LOADING_LENDERS_POSITIONS'
export const START_LOADING_FACILITY_LENDERS_POSITIONS =
  'lenderspositions/START_LOADING_FACILITY_LENDERS_POSITIONS'
export const START_LOADING_LOAN_LENDERS_POSITIONS =
  'lenderspositions/START_LOADING_LOAN_LENDERS_POSITIONS'
export const STOP_LOADING_LENDERS_POSITIONS =
  'lenderspositions/STOP_LOADING_LENDERS_POSITIONS'
export const STOP_LOADING_FACILITY_LENDERS_POSITIONS =
  'lenderspositions/STOP_LOADING_FACILITY_LENDERS_POSITIONS'
export const STOP_LOADING_LOAN_LENDERS_POSITIONS =
  'lenderspositions/STOP_LOADING_LOAN_LENDERS_POSITIONS'
export const CHANGE_LENDERS_FOR_LOAN =
  'lenderspositions/CHANGE_LENDERS_FOR_LOAN'

import React, { useMemo } from 'react';
import { Group, Switch, Text } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import type { MRT_ColumnDef } from 'mantine-react-table';
import IonIcon from '@reacticons/ionicons';
import ExportToCsv from '../ExportToCsv/ExportToCsv';
import { CustomLoaderPage } from '../loader/loader-page';

type Props<T extends Record<string, T>> = {
  tableName: string
  csvExportEnabled?: boolean
  columnDefs: MRT_ColumnDef<T>[]
  data?: T[]
  setRow: (e: any, z: string) => any
  enableColumnOrdering?: boolean
  enableColumnResizing?: boolean
  enableGrouping?: boolean
  enableColumnFilters?: boolean
  enableColumnActions?: boolean
  enableFilters?: boolean
  enableSorting?: boolean
  enableFullScreenToggle?: boolean
  enableDensityToggle?: boolean
  enableHiding?: boolean
  enableTopToolbar?: boolean
  minHeight?: string
  overflow?: string
  withPagination?: boolean
  headerBackgroundColor?: string
  isLoading?: boolean
  rowsPerPage?: number
  border?: boolean
  renderDetailPanel?: (row: any) => React.ReactNode; // Add renderDetailPanel prop
  isAccordionTable?: boolean
  showBottomBoolean?: boolean
  isInactiveLoan?: boolean
  onToggle?: (isActive: boolean) => void;
}

export default function MainTable<
  T extends Record<string, any>
>({
  tableName,
  csvExportEnabled,
  columnDefs,
  data,
  setRow,
  enableColumnOrdering,
  enableColumnResizing,
  enableGrouping,
  enableColumnFilters,
  enableColumnActions,
  enableFilters,
  enableSorting,
  enableFullScreenToggle,
  enableDensityToggle,
  enableHiding,
  enableTopToolbar,
  minHeight,
  overflow,
  headerBackgroundColor,
  withPagination = true,
  isLoading = false,
  border = false,
  rowsPerPage,
  renderDetailPanel,
  isAccordionTable,
  showBottomBoolean,
  isInactiveLoan,
  onToggle,
}: Props<T>) {
  const columns = useMemo<MRT_ColumnDef<T>[]>(() => columnDefs, [])

  const exportData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((row) => {
      const formattedRow = columns.map((col) => {
        const value = row[col.accessorKey as keyof T];
        const key = col.header === 'Actions' ? col.accessorKey : col.header;

        return {
          [`${key}`]: value
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {});

      return formattedRow;
    });
  }, [data, columns]);

  if (isLoading) {
    return <CustomLoaderPage />
  }

  return (
    <Group sx={{
      overflowX: 'auto',
      maxWidth: '100%',
    }}>
      <MantineReactTable
        mantineTableHeadCellProps={{
          sx: {
            backgroundColor: headerBackgroundColor ? headerBackgroundColor : 'none', // Set the background color of header cells to red
            // Hide divider if this is the last column
            '&:last-of-type .mantine-Divider-vertical': {
              display: 'none',
            },
          }
        }}
        defaultColumn={{
          maxSize: 400,
          minSize: 50,
          size: 150,
        }}
        enableColumnResizing={enableColumnResizing ?? true}
        columns={columns}
        data={data ?? []}
        mantineTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5',
              // Ensure no divider for the last column's body cell too
              '&:last-of-type .mantine-Divider-vertical': {
                display: 'none',
              },
            },
            '& tr td:first-of-type': (isAccordionTable ? {
              width: '10px', // Adjust the width of the expand/collapse column
              maxWidth: '10px',
              minWidth: '10px',
              textAlign: 'center', // Center the icon
            } : {}),
          },
        }}
        enableColumnOrdering={enableColumnOrdering ?? true}
        enableGrouping={enableGrouping ?? true}
        enableColumnFilters={enableColumnFilters ?? true}
        enableColumnActions={enableColumnActions ?? true}
        enableFilters={enableFilters ?? true}
        enableSorting={enableSorting ?? true}
        enableFullScreenToggle={enableFullScreenToggle ?? true}
        enableStickyHeader
        enableDensityToggle={enableDensityToggle ?? true}
        enableHiding={enableHiding ?? true}
        enableTopToolbar={enableTopToolbar ?? true}
        renderDetailPanel={renderDetailPanel}

        mantineDetailPanelProps={{ sx: { paddingLeft: '40px !important', paddingRight: '40px !important' } }}
        mantineTableHeadProps={
          isAccordionTable
            ? {
              sx: {
                '& tr th:first-of-type': {
                  width: '10px',
                  maxWidth: '10px',
                  minWidth: '10px',
                  textAlign: 'center',
                },
              },
            }
            : {}
        }
        mantineExpandButtonProps={({ row }) =>
          isAccordionTable
            ? {
              children: row.getIsExpanded() ? (
                <IonIcon name="chevron-down-outline" style={{ fontSize: '16px', color: '#32439A' }} />
              ) : (
                <IonIcon name="chevron-forward-outline" style={{ fontSize: '16px', color: '#32439A' }} />
              ),
            }
            : {}
        }
        mantineTableBodyRowProps={({ row }) => ({
          sx: () => ({
            backgroundColor: row.getIsExpanded() ? '#CAD2FF' : 'transparent', // Background color changes for the expanded row
            '& td': {
              backgroundColor: row.getIsExpanded() ? '#CAD2FF' : 'transparent', // Ensure all cells also change color
            },
          }),
        })}

        mantineTableContainerProps={{
          sx: {
            border: border ? '1px solid #71C0EE' : undefined,
            overflow: overflow ?? 'auto',
            minHeight: minHeight ?? '250px',
            maxWidth: '100%',
          },
        }}
        mantineBottomToolbarProps={{
          mih: "5.5rem",
          sx: {
            backgroundColor: '#f7f7f7',
          }
        }}
        renderBottomToolbarCustomActions={() => (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {csvExportEnabled && (
              <ExportToCsv
                csvName={tableName}
                exportObj={exportData}
                style={{ width: '150px', height: '35px', backgroundColor: '#32439A', color: '#fff' }}
              />
            )}
            {showBottomBoolean && (
              <Group align="center" style={{ marginLeft: '10px' }}>
                <Switch
                  label={
                    <Text className={`status-switch-label ${isInactiveLoan ? 'active' : ''}`}>
                      {isInactiveLoan ? 'Show Active' : 'Show Inactive'}
                    </Text>
                  }
                  checked={isInactiveLoan}
                  onChange={(e) => onToggle?.(e.currentTarget.checked)}
                  size="md"
                  classNames={{
                    track: `status-switch-track ${isInactiveLoan ? 'active' : ''}`,
                    thumb: `status-switch-thumb ${isInactiveLoan ? 'active' : 'inactive'}`,
                  }}
                />
              </Group>
            )}
          </div>
        )}
        mantineTopToolbarProps={{
          sx: {
            maxWidth: '100%',
          }
        }}
        mantineFilterDateInputProps={{
          firstDayOfWeek: 0,
        }}
        mantineTableBodyCellProps={({ cell, row }) => ({
          onClick: () => {
            setRow(row.original, cell.column.id)
          },
        })}
        icons={{
          IconDotsVertical: () => <IonIcon name='funnel-outline' />,
        }}
        // PAGINATION
        enableBottomToolbar={withPagination}
        enablePagination={withPagination}
        autoResetPageIndex={true}
        paginationDisplayMode='pages'
        mantinePaginationProps={{
          rowsPerPageOptions: ["5", "10", "15", "20", "25"],
          size: 'xl',
          radius: 'xl',
          siblings: 1,
          withEdges: false,
        }}
        initialState={{ pagination: { pageSize: rowsPerPage ?? 25, pageIndex: 0 } }}
      />
    </Group>
  )
}

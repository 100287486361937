import React from 'react';
import { Table } from '@mantine/core';
import { DealParams } from 'app/models/deal-params';

type Props = {
    data: DealParams[];
    handleSelectChange: (value: string) => void;
    searchValue: string;
};

export function TradeEntryDealTable({ data, searchValue, handleSelectChange }: Props) {

    // Helper function to highlight matching text
    const highlightText = (text: string) => {
        if (!searchValue) return text; // Return original text if search value is empty

        const parts = text.split(new RegExp(`(${searchValue})`, 'gi')); // Split the text at the matching parts
        return parts.map((part, index) =>
            part.toLowerCase() === searchValue.toLowerCase() ? (
                <span key={index} className='tradeSearchResult'>{part}</span>
            ) : (
                part
            )
        );
    };

    return (
        <div className='tradeTableContainer'>
            <div className='tradeTableScroll'>
                <Table
                    className="tradeTable"
                    highlightOnHover
                >
                    <thead>
                        <tr>
                            <th>
                                Deal Name
                            </th>
                            <th>
                                CUSIP
                            </th>
                            <th>
                                LXID
                            </th>
                            <th>
                                LendOS ID
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((d) => (
                            <tr
                                key={d.id}
                                onClick={() => handleSelectChange(d.id)}
                            >
                                <td>{highlightText(d.dealName)}</td>
                                <td>{highlightText(d.cusip)}</td>
                                <td>{highlightText(d.lxId)}</td>
                                <td>{highlightText(d.id)}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>

    );
}

import React from 'react';
import IonIcon from '@reacticons/ionicons';
import { TextInput, Popover, PopoverProps } from '@mantine/core';

type Props = PopoverProps & {
    searchValue: string;
    setSearchValue: (value: string) => void;
    children: React.ReactNode;
    open: boolean;
    setOpen: (value: boolean) => void;
};

export function SelectWithTable({ setSearchValue, open, setOpen, searchValue, children, ...props }: Props) {



    return (
        <Popover
            opened={open}
            onClose={() => setOpen(false)}
            classNames={props.classNames}
            withArrow
            withinPortal
            position="bottom"
        >
            <Popover.Target>
                <div
                    onClick={() => {
                        setOpen(true);
                    }}
                    style={{ width: '100%' }}
                >
                    <TextInput
                        classNames={{
                            rightSection: "customSellectRightSection",
                            input: "customSelectInput",
                            wrapper: "newInputWrapper",
                        }}
                        rightSection={<IonIcon style={{ width: "15px", height: "15px", top: "2px", left: "2px" }} color="black" name="search-outline" />}
                        placeholder="Search"
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                        onFocus={() => setOpen(true)} // Open dropdown on focus
                        style={{ width: '100%', height: '32px', }}
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown
            >
                {children}
            </Popover.Dropdown>
        </Popover>
    );
}

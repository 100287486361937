import React from 'react';
import { Button, Group, Stack, Text } from '@mantine/core';
import { TradeEntryFacilitiesSection } from './trade-entry-facilities-section';
import { DateInput } from '@mantine/dates';
import IonIcon from '@reacticons/ionicons';
import { tradeTypeOptions, tradeAssociationOptions, tradeDocsTypeOptions, transferTypeOptions, interestTreatmentOptions, assignmentFeePayTypeOptions } from 'app/models/dropdown-options';
import { NewSelectInput } from 'app/views/components/inputs/selects/NewSelectInput';
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput';
import { NewTextInput } from 'app/views/components/inputs/NewTextInput';
import { TradeEntryCounterParty } from './trade-entry-counterparties-section/trade-entry-counter-party';
import { TradeEntryDealSearch } from './trade-entry-deal-section/trade-entry-deal-search';
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle';
type Props = {
  form: any
  onDealChange: (value?: string | null) => void
  onByerSellerChange: (value: any) => void
  createTradeEntry: () => void
  cancelTradeEntry: () => void
  isBuyer: boolean
}

export function TradeEntryFormPresentation({ form, onDealChange, onByerSellerChange, createTradeEntry, cancelTradeEntry, isBuyer }: Props) {
  return (

    <Stack className='tradeEntryCreationPage'>
      <Stack className="tradeEntrySection">
        {/* Info: Booking Details */}

        <SectionTitle>Booking Details</SectionTitle>
        <Group className='tradeEntryInputGroup' >
          <NewTextInput label="Internal Trade ID" w="276px" placeholder='Trade ID' {...form.getInputProps('clientId')} />
          <NewTextInput label="Trader" w="151px" placeholder='Trader' {...form.getInputProps('trader')} />
          <NewTextInput label="PM" w="123px" placeholder='PM' {...form.getInputProps('pm')} />
          <NewTextInput label="Desk" w="83px" placeholder='Desk' {...form.getInputProps('desk')} />
          <NewTextInput label="Trade Group ID" w="123px" placeholder='Trade Group ID' {...form.getInputProps('tradeGroupId')} />
        </Group>
      </Stack >

      {/* Info: Counter Parties */}
      <Stack className="tradeEntrySection">
        <SectionTitle>Counterparties</SectionTitle>
        <Group noWrap className="tradeEntryInputGroup">
          <TradeEntryCounterParty title="My Entity" radio={true} onBuyerSellerChange={onByerSellerChange} isBuyer={isBuyer} form={form} />
          <TradeEntryCounterParty title="Counterparty" isBuyer={!isBuyer} form={form} />
        </Group>
      </Stack>

      <Stack className="tradeEntrySection">
        {/* Info: Terms of Trade */}
        <SectionTitle>Terms of Trade</SectionTitle>
        <Group className="tradeEntryInputGroup">
          <Group noWrap className="dataEntrySubSection">
            <TextLabelInput label="Trade Date">
              <DateInput
                w="130px"
                placeholder='Trade Date'
                valueFormat='MM-DD-YYYY'
                firstDayOfWeek={0}
                classNames={{
                  input: 'newInput',
                  wrapper: 'newInputWrapper',
                }}
                rightSection={<IonIcon name="chevron-down-outline"></IonIcon>}
                {...form.getInputProps('tradeDate')}
              /></TextLabelInput>
          </Group>
          <NewSelectInput label="Trade Type" data={tradeTypeOptions} w="120px" placeholder='Trade Type' {...form.getInputProps('tradeType')} />
          <NewSelectInput label="Trade Association" data={tradeAssociationOptions} w="85px" placeholder='Trade Association' {...form.getInputProps('tradeAssociation')} />
          <NewSelectInput label="Trade Docs" data={tradeDocsTypeOptions} w="110px" placeholder='Trade Docs' {...form.getInputProps('tradeDocsType')} />
          <NewSelectInput label="Transfer Type" data={transferTypeOptions} w="125px" placeholder='Transfer Type' {...form.getInputProps('transferType')} />
          <NewSelectInput label="Interest Treatment" data={interestTreatmentOptions} w="195px" placeholder='Interest Treatment' {...form.getInputProps('interestTreatment')} />
          <NewSelectInput label="Assignment Fee Split" data={assignmentFeePayTypeOptions} w="223px" placeholder='Assignment Fee Split' {...form.getInputProps('assignmentFee')} />
        </Group>
      </Stack>

      {/* Info: Deal Section */}
      <Stack className="tradeEntrySection">
        <SectionTitle>Traded Deal</SectionTitle>
        <Stack className="tradeEntryDealSectionStack">
          <Group noWrap className="tradeEntryCounterpartiesTitleSection">
            <TradeEntryDealSearch onChange={onDealChange} value={form.values.dealId} />
          </Group>
          <Group noWrap className="tradeEntryCounterpartiesSubSections">
            <Group noWrap w="35%" className="dataEntrySubSection">
              <TextLabelInput label="Deal Name" > <Text className='newInput'>{form.values.deal?.dealName ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="12%" className="dataEntrySubSection">
              <TextLabelInput label="CUSIP" > <Text className='newInput'>{form.values.deal?.cusip ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="12%" className="dataEntrySubSection">
              <TextLabelInput label="LX ID" > <Text className='newInput'>{form.values.deal?.lxId ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="30%" className="dataEntrySubSection">
              <TextLabelInput label="Deal ID" > <Text className='newInput'>{form.values.dealId ?? ''}</Text>
              </TextLabelInput>
            </Group>
            <Group noWrap w="10%" className="dataEntrySubSection">
              <TextLabelInput label="Client Deal ID" > <Text className='newInput'></Text>
              </TextLabelInput>
            </Group>

          </Group>
        </Stack>
      </Stack>

      {/* Info: Facility Details */}

      {form.values.tradedFacilities && form.values.tradedFacilities.length > 0 &&
        <TradeEntryFacilitiesSection form={form} />
      }

      {/* Info: Bootons */}
      <Group noWrap className="tradeEntrySubmissionSection">
        <Button className="tradeEntrySubmissionCancel" onClick={cancelTradeEntry}>Cancel</Button>
        <Button className="tradeEntrySubmissionCreate" onClick={createTradeEntry}>Create Trade</Button>
      </Group>
    </Stack>
  );
}

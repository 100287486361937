import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import { EntityProfileParams } from 'app/models/entity-profile-params'
import {
  getLendersPositionsByFacility,
  editLendersPosition,
  changeLendersForFacility,
  getLendersPositionsByLoan,
  getMyCompanyLendersPositions,
  startLoadingFacilityLendersPositions,
  stopLoadingFacilityLendersPositions,
  startLoadingLoanLendersPositions,
  stopLoadingLoanLendersPositions,
  changeLendersForLoan,
  getLendersPositions,
} from './actions'
import {
  ChangeLender,
  LendersPosition,
} from 'app/models/lenders-position-params'
import { StableKey } from 'app/models/common-types'
import { getBusinessDateFromState } from '../business-date/thunks'
import { stringToDate } from 'app/utils/util-functions'
import { EntityParams } from 'app/models/entity-params'

const getAllPositions = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/lenders-positions`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadLendersPositions = () => async (dispatch: Dispatch) => {
  try {
    const [allPositions] = await Promise.all([getAllPositions()])
    return dispatch(getLendersPositions(allPositions))
  } catch (e) {
    console.log(e)
    return []
  } finally {
    // dispatch(stopLoadingEntities())
  }
}

export const loadMyCompanyLendersPositions =
  () => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    try {
      dispatch(startLoadingFacilityLendersPositions())
   
      // Fetch entity profiles, entities, and lender positions
    const [entityProfileResponse, entityResponse, allPositions] = await Promise.all([
      client.get(`${apiServerUrl}/api/entity-profiles`),
      client.get(`${apiServerUrl}/api/active-entities`),
      getAllPositions(),
    ]);

      const entityProfiles: EntityProfileParams[] = entityProfileResponse.data
      const entities: EntityParams[] = entityResponse.data

      // Find the entity profile Ids where isMyCompany is true
      const myCompanyProfileIds = entityProfiles
        .filter(profile => profile.isMyCompany === true)
        .map(profile => profile.id)

      // Filter active entities to only include those entity ids where entityProfile contains an isMyCompany profile
      const myCompanyLenderIds = entities
        .filter(entity =>
          entity.entityProfile.some(profile =>
            myCompanyProfileIds.includes(profile.id),
          ),
        )
        .map(entity => entity.id)

      // Filter lender positions to only include those with lender ids in myCompanyLenderIds
      const myCompanyLendersPositions = allPositions.filter(
        (position: LendersPosition) =>
          myCompanyLenderIds.includes(position.lender.id),
      )
      dispatch(getMyCompanyLendersPositions(myCompanyLendersPositions))
    } catch (error) {
      console.error('Error loading my company lender positions: ', error)
    } finally {
      // Stop loading
      dispatch(stopLoadingFacilityLendersPositions())
    }
  }

export const updateLendersPosition =
  (savedLendersPosition: LendersPosition) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl

    return client
      .put(`${apiServerUrl}/api/lenders-positions`, savedLendersPosition)
      .then(response => {
        dispatch(editLendersPosition(response.data))
        return { success: true, payload: response.data }
      })
      .catch(error => {
        console.log(error)
        return { success: false, payload: error.response.data.error }
      })
  }

export const changeAllLendersForFacility =
  (lendersPoisiotns: ChangeLender[], facilityId: StableKey) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/lenders-positions/facility`, {
        lendersPositions: lendersPoisiotns.map(lendersPosition => ({
          amount: lendersPosition.amount,
          lender: lendersPosition.lender,
          id: lendersPosition.id,
        })),
        facilityId: facilityId,
      })
      .then((response: any) => {
        return dispatch(
          changeLendersForFacility(response.data.map((lp: any) => ({ ...lp }))),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const changeAllLendersForLoan =
  (lendersPoisiotns: ChangeLender[], loanId: StableKey) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/lenders-positions/loan`, {
        lendersPositions: lendersPoisiotns.map(lendersPosition => ({
          amount: lendersPosition.amount,
          lender: lendersPosition.lender,
          id: lendersPosition.id,
        })),
        loanId: loanId,
      })
      .then((response: any) => {
        return dispatch(
          changeLendersForLoan(response.data.map((lp: any) => ({ ...lp }))),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const positionsForLender = async (entityId: string) => {
  const apiServerUrl = config.backendUrl
  return client
    .get(
      `${apiServerUrl}/api/lenders-positions/entity?id=${entityId}&type=Facilities`,
    )
    .then(lendersPositions => {
      return lendersPositions.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadLendersPositionsByFacility =
  (id: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    dispatch(startLoadingFacilityLendersPositions())
    return client
      .get(`${apiServerUrl}/api/lenders-positions/facility?id=${id}`)
      .then(lendersPositions => {
        return dispatch(
          getLendersPositionsByFacility(
            lendersPositions.data.map((lp: any) => ({
              ...lp,
              status: 'Approved',
            })),
          ),
        )
      })
      .catch(e => {
        dispatch(stopLoadingFacilityLendersPositions())
        console.log(e)
        return []
      })
  }

export const loadLendersPositionsByLoan =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(startLoadingLoanLendersPositions())
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/lenders-positions/loan?id=${id}`)
      .then(lendersPositions => {
        return dispatch(
          getLendersPositionsByLoan(
            lendersPositions.data.map((lp: any) => ({ ...lp })),
          ),
        )
      })
      .catch(e => {
        dispatch(stopLoadingLoanLendersPositions())
        console.log(e)
        return []
      })
  }

export const getAmount = (
  lendersPosition: LendersPosition,
  effectiveDate?: Date | null | string,
): number => {
  if (!lendersPosition.proRatas || lendersPosition.proRatas.length == 0) {
    return 0.0
  }
  if (typeof effectiveDate === 'string') {
    effectiveDate = stringToDate(effectiveDate)
  }
  const date = effectiveDate ?? getBusinessDateFromState()
  const proRata = lendersPosition.proRatas
    .filter(pr => {
      const prDate = stringToDate(pr.effectiveDate)
      return prDate <= date
    })
    .pop()
  return proRata ? Number(Number(proRata.amount).toFixed(2)) : 0.0
}

export const getPercentage = (
  lendersPosition: LendersPosition,
  effectiveDate?: Date | null,
): number => {
  if (!lendersPosition.status && lendersPosition.amount) {
    return Number(lendersPosition.amount)
  }
  if (!lendersPosition.proRatas || lendersPosition.proRatas.length == 0) {
    return 0.0
  }

  if (typeof effectiveDate === 'string') {
    effectiveDate = stringToDate(effectiveDate)
  }
  const date = effectiveDate ?? getBusinessDateFromState()
  const proRata = lendersPosition.proRatas
    .filter(pr => {
      const prDate = stringToDate(pr.effectiveDate)
      return prDate <= date
    })
    .pop()
  return proRata ? Number(proRata.percentage) : 0.0
}

export const getAccruedAndPaidInterest = (
  loanId: string,
  lenderId: string,
  effectiveDate: string,
) => {
  const apiServerUrl = config.backendUrl

  return client
    .get(
      `${apiServerUrl}/api/lenders-positions/interest?loanId=${loanId}&lenderId=${lenderId}&effectiveDate=${effectiveDate}`,
    )
    .then(response => {
      return { success: true, payload: response.data }
    })
    .catch(e => {
      console.log(e, 'error')
      return { success: false, payload: e.response.data.error }
    })
}

import {
    Box,
    Group,
    Stack,
} from '@mantine/core'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getFacilities,
    getFacility,
} from 'app/state/ducks/facilities/selectors'
import {
    loadAllFacilities,
    setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { loadAllLoans, loadLockedLoans } from 'app/state/ducks/loans/thunks'
import { getLoanLoader, getLoansByFacility, getLockedLoansByFacility } from 'app/state/ducks/loans/selectors'
import { loadAllDeals, loadOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { DealParams } from 'app/models/deal-params'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadAllLetterOfCredits } from 'app/state/ducks/letters-of-credits/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import { loadAllPrincipalPayments } from 'app/state/ducks/principal-payment/thunks'
import { ContractType } from 'app/models/common-types'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import ExpandableMantineTable from './table-loan'
import { LoanParams } from 'app/models/loan-params'
import DealDetails from './deal-detail'


export default function LoansPage(): JSX.Element {
    const dispatch = useDispatch()
    const { facilityId } = useParams()
    const [deal, setDeal] = useState<DealParams | undefined>(undefined)
    const facility = useSelector(getFacility)
    const deals = useSelector(getDeals)
    const currentDeal = useSelector(getDeal)
    const allFacilities: FacilityParams[] = useSelector(getFacilities)
    const facilityAmendments: FacilityParams[] = useSelector(
        getFacilityAmendments
    )
    const entities = useSelector(getEntities)
    const entityIdToName = new Map<string, string>()
    const businessDate = useSelector(getBusinessDate)

    const data = useSelector(getLoansByFacility);
    const loans = data?.data ?? [];
    const { data: inactiveData, loading: inactiveLoading } = useSelector(getLockedLoansByFacility);
    const [combinedLoans, setCombinedLoans] = useState<LoanParams[]>([]); // New combined state
    const [swinglineLoans, setSwinglineLoans] = useState<LoanParams[]>([]);
    const [nonSwinglineLoans, setNonSwinglineLoans] = useState<LoanParams[]>([]);
    const [globalLoading, setGlobalLoading] = useState(false);
    const loanLoader = useSelector(getLoanLoader);

    // Combine `loanLoader` and `inactiveLoading` into `globalLoading`
    useEffect(() => {
        setGlobalLoading(loanLoader || inactiveLoading);
    }, [loanLoader, inactiveLoading]);
    // Combine `loans` and `inactiveData` into a single array

    useEffect(() => {
        const combined = [...loans, ...(inactiveData ?? [])]; // Merge active and inactive loans
        setCombinedLoans(combined);
    }, [loans, inactiveData]);

    // Filter combined loans into swingline and non-swingline categories
    useEffect(() => {
        if (!combinedLoans.length) return;

        setSwinglineLoans(combinedLoans.filter((loan) => loan.isSwingLine));
        setNonSwinglineLoans(combinedLoans.filter((loan) => !loan.isSwingLine));
    }, [combinedLoans]);

    useEffect(() => {
        if (!facilityId) {
            return
        }
        if (allFacilities) {
            const selectedFacility = allFacilities.find(
                facility => facility.id === facilityId
            )
            if (selectedFacility) {
                selectedFacility.amendment = false
                dispatch(setOneFacility(selectedFacility))
            }
        }
        if (facilityAmendments) {
            const amended_facility = facilityAmendments.find(
                amendment => amendment.id === facilityId
            )
            if (amended_facility) {
                amended_facility.amendment = true
                dispatch(setOneFacility(amended_facility))
            }
        }
    }, [facilityId, facilityAmendments, allFacilities])

    useEffect(() => {
        if (!facilityId) {
            return
        }
        dispatch(loadAllLoans(facilityId))
        dispatch(loadLockedLoans(facilityId))
        dispatch(loadAllLetterOfCredits(facilityId))
        dispatch(loadLendersPositionsByFacility(facilityId))
        dispatch(loadEntities())
        dispatch(loadAllFacilities())
        dispatch(loadWireInstructions())
        dispatch(loadAllDeals())
        dispatch(loadAllFacilityAmendments())
        dispatch(loadIndexRateOptions())
        dispatch(loadAllLoanRollovers())
        dispatch(loadAllLoanIncreases())
        dispatch(loadAllPrincipalPayments())
        dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
    }, [facilityId])

    useEffect(() => {
        if (!facility || !deals) {
            return
        }
        const deal = deals.find(deal => deal.id === facility.dealId.id)
        if (deal) {
            setDeal(deal)
        } else {
            dispatch(loadOneDeal(facility.dealId.id, 'Terminated'))
        }
    }, [facility, deals])

    useEffect(() => {
        if (!currentDeal) {
            return
        }
        setDeal(currentDeal)
    }, [currentDeal])

    if (Array.isArray(entities)) {
        for (const entity of entities) {
            if (entity.id) {
                entityIdToName.set(entity.id, entity.entityName)
            }
        }
    }

    return (
        <Stack w="100%" align='stretch'>
            <Box style={{ marginBottom: '20px' }}><ExpandableMantineTable title={'Loans Within Facility'} loans={nonSwinglineLoans} globalLoading={globalLoading} /></Box>
            <Box style={{ marginBottom: '20px' }}><ExpandableMantineTable title={'Swingline Loans within Facility'} loans={swinglineLoans} globalLoading={globalLoading} /></Box>
            <Stack w="100%" className='sectionRemoveGap'>
                <SectionTitle>Deal Details</SectionTitle>
                <Stack w='100%' className='sectionRemoveGap sectionBody'>
                    <DealDetails deal={deal}
                        entityIdToName={entityIdToName}
                        businessDate={businessDate} />
                </Stack>
            </Stack>
            <Group w="100%" noWrap mt='30px'>
                <NotesAndAttachment
                    itemId={facility?.id ?? ''}
                    type={ContractType.Facilities}
                    itemAdmin={facility?.accountManagementAdmin ?? ''}
                />
            </Group>
        </Stack >
    )
}
import React from 'react';
import { Stack } from '@mantine/core';
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle';
import MainTable from 'app/views/components/Table/MainTable';
import { LoanParams } from 'app/models/loan-params';
import { columnDefs } from './loans-page-coumnDefs';
import LoanDetailPanel from './loan-detail-panel';


interface ExpandableMantineTableProps {
  loans: LoanParams[];
  globalLoading: boolean;
  title: string;
}

const ExpandableMantineTable: React.FC<ExpandableMantineTableProps> = ({ loans, globalLoading, title }) => {
  const [isInactiveLoan, setIsInactiveLoan] = React.useState(false);

  const handleToggle = (isActive: boolean) => {
    setIsInactiveLoan(isActive);
  };

  const filteredLoans = React.useMemo(() => {
    if (isInactiveLoan) {
      return loans.filter((loan) => loan.status === 'Locked');
    }
    return loans.filter((loan) => loan.status !== 'Locked');
  }, [loans, isInactiveLoan]);

  const renderDetailPanel = (row: { original: LoanParams; getIsExpanded: () => boolean }) => (
    <LoanDetailPanel row={row.original} isExpanded={row.getIsExpanded()} />
  );

  return <><SectionTitle>{title}</SectionTitle>
    <Stack w='100%' className='sectionRemoveGap sectionBody' >
      <Stack w='100%' pt='12px' className='sectionDetailBody' style={{ padding: '40px' }}>
        {columnDefs.length != 0 ? (
          <MainTable
            tableName={title}
            columnDefs={columnDefs}
            withPagination={true}
            data={filteredLoans ?? []}
            setRow={() => { }}
            renderDetailPanel={({ row }) => renderDetailPanel(row)}
            csvExportEnabled
            enableTopToolbar={false}
            headerBackgroundColor='#F0EEEE'
            isLoading={globalLoading}
            rowsPerPage={5}
            isAccordionTable={true}
            showBottomBoolean={true}
            isInactiveLoan={isInactiveLoan}
            onToggle={(isActive) => handleToggle(isActive)}
            border={true}
          />) : null}
      </Stack>
    </Stack >
  </>;
};


export default ExpandableMantineTable;


import { Group, Stack, TextInput, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getAccruedAndPaidInterest } from 'app/state/ducks/lenders-positions/thunks'
import { formatDateToMDY, formatDateToUTC, formatNumberToCurrency } from 'app/utils/util-functions'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { TextLabelInput } from 'app/views/components/inputs/TextLabelInput'
import { DateInput } from '@mantine/dates'
import IonIcon from '@reacticons/ionicons'


interface Props {
    lendersPosition: any
    currency: string
}


export default function LenderInterestComponent({
    lendersPosition,
    currency,
}: Props) {
    const businessDate = useSelector(getBusinessDate);
    const [effectiveDate, setEffectiveDate] = useState<Date | null>(businessDate);
    const [interestEarned, setInterestEarned] = useState<string>("");
    const [interestPaid, setInterestPaid] = useState<string>("");

    const setInterests = async (date: Date | null) => {
        if (!date) {
            return
        }
        const result = await getAccruedAndPaidInterest(lendersPosition.contractId.id, lendersPosition.lender.id, formatDateToUTC(date));
        const interestEarned = formatNumberToCurrency(result.payload.interestEarned, currency ?? 'USD')
        const interestPaid = formatNumberToCurrency(result.payload.interestPaid, currency ?? 'USD')
        setInterestEarned(interestEarned);
        setInterestPaid(interestPaid);
    }

    useEffect(() => {
        setInterests(effectiveDate);
    }, [lendersPosition, businessDate])

    return (
        <Stack w="100%" className='modal-body'>
            <Text className='sectionDetailHeader'>Interest Accrual</Text>
            <Group className="singleRowInputGroup" w="100%">
                <Group noWrap className="dataEntrySubSection" w="100%" position='apart'>
                    <Group noWrap w="30%">
                        <TextLabelInput label="As of Date">
                            <DateInput
                                style={{ flex: "1 1 0%" }}
                                placeholder={effectiveDate ? formatDateToMDY(effectiveDate) : formatDateToMDY(new Date())}
                                valueFormat='MM-DD-YYYY'
                                firstDayOfWeek={0}
                                classNames={{
                                    input: 'newInput',
                                    wrapper: 'newInputWrapper',
                                }}
                                rightSection={<IonIcon name="chevron-down-outline"></IonIcon>}
                                onChange={(value: any) => {
                                    setEffectiveDate(value);
                                    setInterests(value);
                                }}
                            />
                        </TextLabelInput>
                    </Group>
                    <Group noWrap w="30%">
                        <TextLabelInput label="Interest Earned">
                            <TextInput
                                style={{ flex: "1 1 0%" }}
                                placeholder="Interest Earned"
                                classNames={{
                                    input: 'newInput',
                                    wrapper: 'newInputWrapper',
                                }}
                                value={interestEarned}
                            />
                        </TextLabelInput>
                    </Group>
                    <Group noWrap w="30%">
                        <TextLabelInput label="Interest Paid">
                            <TextInput
                                style={{ flex: "1 1 0%" }}
                                placeholder="Interest Paid"
                                classNames={{
                                    input: 'newInput',
                                    wrapper: 'newInputWrapper',
                                }}
                                value={interestPaid}
                            />
                        </TextLabelInput>
                    </Group>
                </Group>
            </Group>
        </Stack >
    )
}
import IonIcon from '@reacticons/ionicons';

interface Props {
  direction: 'up' | 'down' | 'none';
  size?: string;
}

export function UpDownIndicator({ direction, size }: Props) {
  if (direction === 'none') return null;

  return (
    <IonIcon
      name={`caret-${direction}`}
      style={{
        height: size ?? '1.5em',
        width: size ?? '1.5em',
        color: (direction === 'up') ? 'green' : 'red',
      }}
    />
  );
}
import { Radio, Stack, Text } from '@mantine/core'
import { ContactParams } from 'app/models/contact-params'
import { updateLendersPosition } from 'app/state/ducks/lenders-positions/thunks'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ContactTable from './contact-table'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'

interface Props {
  lendersPosition: any
  contacts: ContactParams[]
  changeTabToWireInstructions?: any
  isDrawer?: boolean
}

export default function ContactsAccordion({
  lendersPosition,
  contacts,
  changeTabToWireInstructions,
  isDrawer=false,
}: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState<{ id: string; admin: string } | null>(
    lendersPosition?.adminContactId ?? null
  )
  const [credit, setCredit] = useState<{ id: string; admin: string } | null>(
    lendersPosition?.creditContactId ?? null
  )

  const changeAdminContact = (contactId: string) => {
    const selectedContact = contacts.find(contact => contact.id == contactId)
    setAdmin(
      selectedContact
        ? { id: selectedContact.id ?? '', admin: selectedContact.maker ?? '' }
        : null
    )
  }

  useEffect(() => {
    setAdmin(lendersPosition?.adminContactId ?? null)
    setCredit(lendersPosition?.creditContactId ?? null)
  }, [lendersPosition, contacts])

  const changeCreditContact = (contactId: string) => {
    const selectedContact = contacts.find(contact => contact.id == contactId)
    setCredit(
      selectedContact
        ? { id: selectedContact.id ?? '', admin: selectedContact.maker ?? '' }
        : null
    )
  }

  const saveContacts = async () => {
    setLoading(true)
    lendersPosition.adminContactId = admin
    lendersPosition.creditContactId = credit
    const response: any = await dispatch(updateLendersPosition(lendersPosition))
    setLoading(false)
    if (!response.success) {
      ErrorNotification({
        title: 'Lender Position Updated Failed',
        message: response.payload ?? 'Check your input and try again',
      }, isDrawer)
      return
    }
    SuccessNotification({
      title: 'Successful Lender Position Updated',
      message: 'You can also set wire instructions',
    }, isDrawer)
    changeTabToWireInstructions()
  }

  return (
    <Stack w="100%" className='sectionRemoveGap'>
      <SectionTitle sm={true}>Contacts</SectionTitle>
      <Stack w='100%' className='sectionRemoveGap sectionBody'>
        <Stack w='100%' className='sectionDetailBody'>
          <Text className='sectionDetailHeaderDrawer'>Admin Contacts</Text>
          <Stack>
            <Radio.Group
              className="tradeEntryRadioButtons"
              size="lg"
              value={admin?.id ?? ''}
              onChange={changeAdminContact}
            >
              <ContactTable
                contacts={contacts?.filter(
                  contact => contact.contactType == 'Admin'
                )}
              ></ContactTable>
            </Radio.Group>
          </Stack>

          <Text className='sectionDetailHeaderDrawer'>Credit Contacts</Text>
          <Stack>
            <Radio.Group
              className="tradeEntryRadioButtons"
              size="lg"
              value={credit?.id ?? ''}
              onChange={changeCreditContact}
            >
              <ContactTable
                contacts={contacts?.filter(
                  contact => contact.contactType == 'Credit'
                )}
              />
            </Radio.Group>
          </Stack>
          <PrimaryButton
            text="Save"
            loading={loading}
            onClick={() => {
              saveContacts()
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

import { LoanHistoryData, LoanParams } from 'app/models/loan-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface LoanState {
  approvedLoans: LoanParams[]
  loanHistory: LoanHistoryData[]
  loan?: LoanParams
  allLoans: LoanParams[]
  facilityLoans: { data: LoanParams[]; loading: boolean }
  facilityLockedLoans: { data: LoanParams[]; loading: boolean }
}

const initialState: LoanState = {
  approvedLoans: [],
  facilityLoans: { data: [], loading: false },
  facilityLockedLoans: { data: [], loading: false },
  loanHistory: [],
  allLoans: [],
  loan: undefined,
}

const loansReducer = createReducer(initialState)({
  [types.GET_LOANS_BY_FACILITY]: (state: LoanState, payload: any) => {
    return {
      ...state,
      facilityLoans: { data: payload.payload, loading: false },
    }
  },
  [types.GET_LOCKED_LOANS_BY_FACILITY]: (state: LoanState, payload: any) => {
    return {
      ...state,
      facilityLockedLoans: { data: payload.payload, loading: false },
    }
  },
  [types.GET_ALL_LOANS]: (state: LoanState, payload: any) => {
    return {
      ...state,
      allLoans: payload.payload,
      approvedLoans: payload.payload.filter(
        (loan: any) => loan.status === 'Approved',
      ),
    }
  },
  [types.GET_LOAN]: (state: LoanState, payload: any) => {
    return { ...state, loan: payload.payload }
  },

  [types.GET_APPROVED_LOANS]: (state: LoanState, payload: any) => {
    return { ...state, approvedLoans: payload.payload }
  },

  [types.GET_ALL_APPROVED_LOANS]: (state: LoanState, payload: any) => {
    return {
      ...state,
      approvedLoans: payload.payload.map((loan: any) => ({
        ...loan,
        status: 'Approved',
      })),
    }
  },

  [types.GET_LOCKED_LOANS]: (state: LoanState, payload: any) => {
    return { ...state, locedLoans: payload.payload }
  },

  [types.EDIT_LOAN]: (state: LoanState, payload: any) => {
    const index = state.facilityLoans.data.findIndex(
      loan => loan.id === payload.payload.data.id,
    )
    const newArray = state.facilityLoans.data
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    const index2 = state.allLoans.findIndex(
      loan => loan.id === payload.payload.data.id,
    )
    const newArray2 = state.allLoans
    newArray2[index2] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      facilityLoan: { data: newArray, loading: false },
      allLoans: newArray2,
    }
  },

  [types.ADD_LOAN]: (state: LoanState, payload: any) => {
    return {
      ...state,
      allLoans: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.allLoans,
      ],
      facilityLoans: {
        data: [
          { ...payload.payload.data, status: 'Draft' },
          ...state.facilityLoans.data,
        ],
        loading: false,
      },
    }
  },

  [types.SUBMIT_LOAN]: (state: LoanState, payload: any) => {
    if (payload.payload.error) {
      return {
        ...state,
      }
    }
    const facilityLoansNewArray = [...state.facilityLoans.data]
    const facilityLoanIndex = facilityLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (facilityLoanIndex >= 0) {
      facilityLoansNewArray[facilityLoanIndex].status = 'Submitted'
    }
    const allLoansNewArray = [...state.allLoans]
    const index = allLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (index >= 0) {
      allLoansNewArray[index].status = 'Submitted'
    }
    return {
      ...state,
      facilityLoans: { data: facilityLoansNewArray, loading: false },
      allLoans: allLoansNewArray,
    }
  },

  [types.APPROVE_LOAN]: (state: LoanState, payload: any) => {
    if (payload.payload.error) {
      return {
        ...state,
      }
    }
    const facilityLoansNewArray = [...state.facilityLoans.data]
    const facilityLoanIndex = facilityLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (facilityLoanIndex >= 0) {
      facilityLoansNewArray[facilityLoanIndex].status = 'Approved'
    }
    const allLoansNewArray = [...state.allLoans]
    const index = allLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (index >= 0) {
      allLoansNewArray[index].status = 'Approved'
    }
    const approvedLoansNewArray = [...state.approvedLoans]
    if (facilityLoanIndex >= 0 || index >= 0) {
      approvedLoansNewArray.push(
        facilityLoanIndex >= 0
          ? facilityLoansNewArray[facilityLoanIndex]
          : allLoansNewArray[index],
      )
    }

    return {
      ...state,
      facilityLoans: { data: facilityLoansNewArray, loading: false },
      allLoans: allLoansNewArray,
      approvedLoans: approvedLoansNewArray,
    }
  },

  [types.REJECT_LOAN]: (state: LoanState, payload: any) => {
    const facilityLoansNewArray = [...state.facilityLoans.data]
    const facilityLoanIndex = facilityLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (facilityLoanIndex >= 0) {
      facilityLoansNewArray[facilityLoanIndex].status = 'Draft'
    }
    const allLoansNewArray = [...state.allLoans]
    const index = allLoansNewArray.findIndex(
      loan => loan.id == payload.payload.id,
    )
    if (index >= 0) {
      allLoansNewArray[index].status = 'Draft'
    }
    return {
      ...state,
      facilityLoans: { data: facilityLoansNewArray, loading: false },
      allLoans: allLoansNewArray,
    }
  },

  [types.DELETE_LOAN]: (state: LoanState, payload: any) => {
    const facilityLoansNewArray = [...state.facilityLoans.data]
    const index = facilityLoansNewArray.findIndex(
      loan => loan.id == payload.payload[0].id,
    )
    if (index >= 0) {
      facilityLoansNewArray.splice(index, 1)
    }
    console.log(facilityLoansNewArray)
    const allLoansNewArray = [...state.allLoans]
    const index2 = allLoansNewArray.findIndex(
      loan => loan.id == payload.payload[0].id,
    )
    if (index2 >= 0) {
      allLoansNewArray.splice(index2, 1)
    }
    return {
      ...state,
      facilityLoansNewArray: { data: facilityLoansNewArray, loading: false },
      allLoans: allLoansNewArray,
      facilityLoans: { data: facilityLoansNewArray, loading: false },
    }
  },

  [types.GET_LOAN_HISTORY]: (state: LoanState, payload: any) => {
    return { ...state, loanHistory: payload.payload }
  },
  [types.START_LOADING_FACILITY_LOANS]: (state: LoanState) => {
    return {
      ...state,
      facilityLoans: { data: [], loading: true },
      facilityLockedLoans: { data: [], loading: true },
    }
  },
  [types.STOP_LOADING_FACILITY_LOANS]: (state: LoanState) => {
    return {
      ...state,
      facilityLoans: { ...state.facilityLoans, loading: false },
      facilityLockedLoans: { ...state.facilityLockedLoans, loading: false },
    }
  },
})

export default loansReducer

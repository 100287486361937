import {
    Anchor,
    Breadcrumbs,
    Group,
    Modal,
    Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { loadOneFacility, setOneFacility } from 'app/state/ducks/facilities/thunks'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { UpdatedFacilitiesScreen } from '../facilities/updated-facilities-screen'
import { loadAllDeals, setOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeals } from 'app/state/ducks/deals/selectors'
import {
    loadLendersPositionsByFacility,
    loadLendersPositionsByLoan,
} from 'app/state/ducks/lenders-positions/thunks'
import { LendersPosition } from 'app/models/lenders-position-params'
import {
    getFacilityLendersPositionsWithLoading,
    getLoanLendersPositionsWithLoading,
} from 'app/state/ducks/lenders-positions/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { DealParams } from 'app/models/deal-params'
import { loadOneLoan } from 'app/state/ducks/loans/thunks'
import { getLoan } from 'app/state/ducks/loans/selectors'
import { useListState } from '@mantine/hooks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { stringToDate } from 'app/utils/util-functions'
import LenderAllocationTable from './funds-table'
import FundManagerLenderTable from './fund-managers-table'
import LenderAllocationsHeader from './lender-allocations-header'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { FacilityParams } from 'app/models/facility-params'

interface props {
    tab: string
}

export function LenderAllocationsDrillPage({ tab }: props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { contractId, status, contractType } = useParams()

    const [openEdit, setOpenEdit] = useState<boolean>(false)

    const [effectiveDate, setEffectiveDate] = useState<Date>(new Date())
    const [deal, setDeal] = useState<DealParams>()
    const [lendersPositions, lendersPositionshandler] =
        useListState<LendersPosition>([])

    const [loading, setLoading] = useState<boolean>(false)

    const { data: facilityLendersPositions, isLoading: loadingFacilityLendersPositions } = useSelector(
        getFacilityLendersPositionsWithLoading
    )
    const { data: loanLendersPositions, isLoading: loadingLoanLendersPositions } = useSelector(
        getLoanLendersPositionsWithLoading
    )
    const facility = useSelector(getFacility)
    const deals = useSelector(getDeals)
    const loan = useSelector(getLoan)
    const currentDate = useSelector(getBusinessDate)
    const facilityAmendments: FacilityParams[] = useSelector(
        getFacilityAmendments
    )

    useEffect(() => {
        dispatch(loadEntities())
        dispatch(loadContacts())
        dispatch(loadWireInstructions())
        dispatch(loadAllDeals())
        dispatch(loadIndexRateOptions())
    }, [])

    useEffect(() => {
        const contractStartDate = stringToDate((contractType === 'loans' ? loan?.startDate : facility?.startDate) ?? '')
        setEffectiveDate(contractStartDate > currentDate ? contractStartDate : currentDate)
    }, [facility, loan, currentDate])

    useEffect(() => {
        if (contractType === 'loans') {
            if (!loanLendersPositions) {
                return
            }
            lendersPositionshandler.setState(loanLendersPositions)
            return
        }
        if (contractType === 'facilities') {
            if (!facilityLendersPositions) {
                return
            }
            lendersPositionshandler.setState(facilityLendersPositions)
            return
        }
        lendersPositionshandler.setState([])
    }, [facilityLendersPositions, loanLendersPositions, contractType])


    useEffect(() => {
        if (contractType === 'loans') {
            setLoading(loadingLoanLendersPositions)
        }
        if (contractType === 'facilities') {
            setLoading(loadingFacilityLendersPositions)
        }
    }, [loadingFacilityLendersPositions, loadingLoanLendersPositions, contractType])

    useEffect(() => {
        if (contractType === 'loans') {
            dispatch(loadLendersPositionsByLoan(contractId ?? ''))
            dispatch(loadOneLoan(contractId ?? '', status ?? ''))
            return
        }
        if (contractType === 'facilities') {
            dispatch(loadAllFacilityAmendments())
            dispatch(loadLendersPositionsByFacility(contractId ?? ''))
            dispatch(loadOneFacility(contractId ?? '', status ?? ''))
            return
        }
    }, [contractId, contractType, status])

    useEffect(() => {
        if (!facilityAmendments || contractType !== 'facilities') {
            return
        }
        const amended_facility = facilityAmendments.find(
            amendment => amendment.id === contractId
        )
        if (amended_facility) {
            dispatch(setOneFacility(amended_facility))
        }
    }, [facility, facilityAmendments])

    useEffect(() => {
        if (!loan || contractType !== 'loans') {
            return
        }
        if (loan.status === "Locked") {
            dispatch(loadOneFacility(loan.facilityId.id, 'Locked'))
        }
        dispatch(loadOneFacility(loan.facilityId.id, 'Approved'))
    }, [loan, contractType])

    useEffect(() => {
        if (!facility || !deals) {
            return
        }
        const selectedDeal = deals.find(
            (deal: any) => deal.id === facility.dealId.id
        )
        if (!selectedDeal) {
            return
        }
        dispatch(setOneDeal(selectedDeal))
        setDeal(selectedDeal)
    }, [facility, deals])

    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; tableRender: JSX.Element } } = {
        lenderAllocationsFund: {
            route: `/dealmanagement/${contractType}/${contractId}/${status}/lenders`, label: 'FUND', active: tab === 'lenderAllocationsFund' ? true : false, tableRender: <LenderAllocationTable
                effectiveDate={effectiveDate}
                currency={contractType === 'facilities'
                    ? facility?.baseCurrency ?? 'USD'
                    : loan?.currency ?? 'USD'
                }
                lendersPositions={lendersPositions}
                loading={loading}
            />
        },
        lenderAllocationsFundManager: {
            route: `/dealmanagement/${contractType}/${contractId}/${status}/lenders/fund-manager`, label: 'FUND MANAGER', active: tab === 'lenderAllocationsFundManager' ? true : false, tableRender: <FundManagerLenderTable
                effectiveDate={effectiveDate}
                currency={contractType === 'facilities'
                    ? facility?.baseCurrency ?? 'USD'
                    : loan?.currency ?? 'USD'
                }
                lendersPositions={lendersPositions}
                loading={loading}
            />
        },
    }

    const items = [
        { title: 'Deal Management', href: '/dealmanagement' },
        { title: 'Facilities', href: '/dealmanagement/facilities' },
        { title: 'Facility', href: `/dealmanagement/facilities/${facility?.id}/${facility?.status}` },
        { title: 'Loans', href: `/dealmanagement/facilities/${facility?.id}/${facility?.status}/loans` }
    ].map((item, index) => (
        <Anchor
            onClick={item.href ? () => navigate(item.href) : () => null}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    return (
        <Stack w="100%">
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
            >
                {items}
            </Breadcrumbs>
            {facility ? <LenderAllocationsHeader facility={facility} /> : null}
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    {contractType == 'facilities' && facility?.status === 'Draft' && (
                        <PrimaryInput
                            className='activityButton'
                            text="Edit Lender Allocations"
                            onClick={() => setOpenEdit(true)}
                        />
                    )}
                </Group>
            </Group>
            <Group noWrap w='100%' mt='10px'>
                {tabs[tab].tableRender}
            </Group>
            {contractType == 'facilities' && facility?.status === 'Draft' && (
                <Modal
                    className="modal-body modal-body-template fit-content-modal"
                    opened={openEdit}
                    onClose={() => setOpenEdit(false)}
                    centered={true}
                    size="77%"
                >
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        facility={facility}
                        onClose={() => setOpenEdit(false)}
                        step={1}
                    />
                </Modal>
            )}
        </Stack>

    )
}
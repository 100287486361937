import { notifications } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons-react'
import React from 'react'

type Props = {
  color?: string
  title?: string
  message?: string
  duration?: number
}

export const DisplayNotification: React.FC<Props> = ({
  color,
  title,
  message,
  duration = 7000,
}, isDrawer = false) => {
  notifications.show({
    color: color,
    title: title,
    message: message,
    autoClose: duration,
    sx: {
      position: 'fixed',
      top: isDrawer ? '0' : '5.625rem',
      left: isDrawer ? '75%' : '50%',
      transform: 'translateX(-50%)',
    },
  })
  return null
}

export const SuccessNotification: React.FC<Props> = ({
  title,
  message,
  duration = 3000,
}, isDrawer = false) => {
  notifications.show({
    icon: <IconCheck />,
    color: '#309A22',
    title: title,
    message: message,
    autoClose: duration,
    closeButtonProps: { iconSize: '100px', color: '#309A22' },
    sx: {
      backgroundColor: '#f4fff2',
      border: '1px solid #8bb184',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
      position: 'fixed',
      top: isDrawer ? '0' : '5.625rem',
      left: isDrawer ? '75%' : '50%',
      transform: 'translateX(-50%)',
    },
    styles: () => ({
      icon: { backgroundColor: '#309A22', color: '#ffffff' },
      title: { color: '#309A22' },
      description: { color: '#4b7745' },
      closeButton: {
        color: '#309A22',
        '&:hover': {
          backgroundColor: 'transparent', // Ensures background stays the same
          color: '#309A22', // Keeps color the same on hover
        },
      },
    }),
  });
  return null;
};


export const ErrorNotification: React.FC<Props> = ({
  title,
  message,
  duration = 7000,
}, isDrawer = false) => {
  notifications.show({
    className: 'errorNotification',
    icon: <IconX />,
    color: 'red',
    title: title,
    message: message,
    autoClose: duration,
    closeButtonProps: { iconSize: '100px', color: '#9A2222' },
    sx: {
      backgroundColor: '#FFEAEA',
      border: '1px solid #b18484',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
      position: 'fixed',
      top: isDrawer ? '0' : '5.625rem',
      left: isDrawer ? '75%' : '50%',
      transform: 'translateX(-50%)',
    },
    styles: () => ({
      icon: { backgroundColor: '#9A2222', color: '#ffffff' },
      title: { color: '#9A2222' },
      description: { color: '#774545' },
      closeButton: {
        color: '#9A2222',
        '&:hover': {
          backgroundColor: 'transparent', // Prevents color change on hover
          color: '#9A2222', // Keeps text color consistent
        },
      },
    }),
  });
  return null;
};

import { Text } from '@mantine/core';

interface Props {
  label: string;
  children?: React.ReactNode; // Add children to the interface
}

export function TextLabelInput({ label, children }: Props) {
  return (
    <>
      <Text className="dataEntryLabel">{label}:</Text>
      {typeof children === 'string' ? <Text className="dataEntryInput">{children}</Text> : children}
    </>
  );
}
